import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

import BG from "../../assets/images/BG.svg";

import CareersBackground from "../../assets/images/careers-bg.png";
import CareersBackground_Mobile from "../../assets/images/careers-bg-mobile.png";
import "./Careers.scss";

function Careers() {
  const height = window.innerHeight;
  const width = window.innerWidth;
  const [offsetY, setOffsetY] = useState(0);
  const [activeJob, setActiveJob] = useState(0);
  const [animTrigger, setAnimTrigger] = useState(true);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  const [activeTitle, setActiveTitle] = useState("Project Coordinators");
  const [activeDesc, setActiveDesc] =
    useState(`TopLogic is searching for someone who has a great deal of
  passion in solving client issues. We have been established as
  a customer-focused company, and perhaps if we share the same
  outlook, this job is for you. If you solve problems in an
  efficient yet effective manner, you may be called upon to
  manage e-mail support, become an inbound phone advisor, or a
  combination of both if need be.`);

  const handleAnimation = () => {
    setAnimTrigger(false);
    setTimeout(() => {
      setAnimTrigger(true);
    }, 300);
  };

  const jobPostsContainer = document.getElementsByClassName(
    "careers__container-job-posts"
  );

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 960px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const titleAnim = {
    initial: { opacity: 0, x: -10 },
    in: {
      x: 0,
      opacity: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
      },
    },
    out: {
      opacity: 0,
      x: -10,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
      },
      delay: 0.3,
    },
  };

  function handleClick(index) {
    setActiveJob(index);
    handleAnimation();

    setTimeout(() => {
      if (index == 0) {
        setActiveTitle("Project Coordinators");
        setActiveDesc(`TopLogic is searching for someone who has a great deal of
        passion in solving client issues. We have been established as
        a customer-focused company, and perhaps if we share the same
        outlook, this job is for you. If you solve problems in an
        efficient yet effective manner, you may be called upon to
        manage e-mail support, become an inbound phone advisor, or a
        combination of both if need be.`);
      } else {
        setActiveTitle("Software Engineers");
        setActiveDesc(`Have a knack for solving problems? Our company is looking
        forward to meet self-motivated candidates who aren't just a
        team player, but also thrives in a fast-paced environment. If
        you're smart, creative, and ambitious as a software engineer,
        we'd like to talk to you. We are always looking for a better
        solution, and in doing so, your expertise in protocol stack
        development, web and mobile systems architecture and
        frameworks engineering would be massively beneficial.`);
      }
    }, 300);
  }

  const ButtonNext = () => {
    return (
      <motion.div
        className='careers__toggle-button next'
        onClick={() => {
          handleClick(1);
        }}
      >
        <FaArrowRight />
      </motion.div>
    );
  };

  const ButtonPrev = () => {
    return (
      <motion.div
        className='careers__toggle-button prev'
        onClick={() => {
          handleClick(0);
        }}
      >
        <FaArrowLeft />
      </motion.div>
    );
  };

  return (
    <motion.div
      className='careers'
      style={{
        height: height,
        width: width,
      }}
    >
      {isDesktopOrLaptop && (
        <>
          <motion.div className='careers__container'>
            <img
              src={CareersBackground}
              alt='team-photo'
              style={{
                zIndex: -1,
              }}
            />
            <div className='careers__container-title'>
              <div className='careers__container-title-h1'>Join Us</div>
              <img src={BG} alt='accent'></img>
              <div className='careers__container-title-h2'>
                Send an e-mail to us at
                <span className='email'> consulting@toplogic.com.ph</span> and
                find out how you can make a difference.
              </div>
            </div>
            <div className='careers__container-job-posts'>
              <div className='careers__container-job-post-1'>
                <div className='job-title item-1'>Project Coordinators</div>
                <div className='job-desc item-1'>
                  TopLogic is searching for someone who has a great deal of
                  passion in solving client issues. We have been established as
                  a customer-focused company, and perhaps if we share the same
                  outlook, this job is for you. If you solve problems in an
                  efficient yet effective manner, you may be called upon to
                  manage e-mail support, become an inbound phone advisor, or a
                  combination of both if need be.
                </div>
              </div>
              <div className='careers__container-job-post-2'>
                <div className='job-title item-2'>Software Engineers</div>
                <div className='job-desc item-2'>
                  Have a knack for solving problems? Our company is looking
                  forward to meet self-motivated candidates who aren't just a
                  team player, but also thrives in a fast-paced environment. If
                  you're smart, creative, and ambitious as a software engineer,
                  we'd like to talk to you. We are always looking for a better
                  solution, and in doing so, your expertise in protocol stack
                  development, web and mobile systems architecture and
                  frameworks engineering would be massively beneficial.
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
      {isTabletOrMobile && (
        <>
          <motion.div className='careers__container'>
            {/* <ButtonNext />
            <ButtonPrev /> */}
            <img
              src={CareersBackground_Mobile}
              alt='team-photo'
              style={{
                zIndex: -1,
                top: "25%",
                // transform: `translateY(-30%) translateX(-${offsetY * 0.1}px)`,
                transform: `translateY(-30%) translateX(-40%)`,
                // filter: `blur(${offsetY * 0.0009}px)`,
              }}
            />
            <div className='careers__container-title'>
              <div className='careers__container-title-h1'>Join Us</div>
              <div className='careers__container-title-h2'>
                Send an e-mail to us at
                <span className='email'> consulting@toplogic.com.ph</span> and
                find out how you can make a difference.
              </div>
            </div>
            <motion.div className='careers__container-job-posts-v2'>
              <motion.div
                className='job-title__container'
                initial='initial'
                animate={animTrigger ? "in" : "out"}
                variants={titleAnim}
              >
                {activeTitle}
              </motion.div>
              <motion.div className='job-title__index'>
                <motion.div
                  className='ind 1'
                  style={{
                    backgroundColor:
                      activeJob === 0 ? "var(--primary)" : "var(--l-grey)",
                    cursor: "pointer",
                  }}
                  initial={{
                    width: "15%",
                  }}
                  animate={{
                    width: activeJob === 0 ? "20%" : "15%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.5,
                    delay: 0,
                  }}
                  onClick={() => {
                    handleClick(0);
                  }}
                ></motion.div>
                <motion.div
                  className='ind 2'
                  style={{
                    backgroundColor:
                      activeJob === 1 ? "var(--primary)" : "var(--l-grey)",
                    cursor: "pointer",
                  }}
                  initial={{
                    width: "15%",
                  }}
                  animate={{
                    width: activeJob === 1 ? "20%" : "15%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.5,
                    delay: 0,
                  }}
                  onClick={() => {
                    handleClick(1);
                  }}
                ></motion.div>
              </motion.div>
              <motion.div
                className='job-description__container'
                initial='initial'
                animate={animTrigger ? "in" : "out"}
                variants={titleAnim}
              >
                {activeDesc}
              </motion.div>
            </motion.div>
            {/* <div className='careers__container-job-posts' ref={ref}>
              <div className='careers__container-job-post-1'>
                <div className='job-title item-1'>PROJECT COORDINATORS</div>
                <div className='job-desc item-1'>
                  TopLogic is searching for someone who has a great deal of
                  passion in solving client issues. We have been established as
                  a customer-focused company, and perhaps if we share the same
                  outlook, this job is for you. If you solve problems in an
                  efficient yet effective manner, you may be called upon to
                  manage e-mail support, become an inbound phone advisor, or a
                  combination of both if need be.
                </div>
              </div>
              <div className='careers__container-job-post-2'>
                <div className='job-title item-2'>SOFTWARE ENGINEERS</div>
                <div className='job-desc item-2'>
                  Have a knack for solving problems? Our company is looking
                  forward to meet self-motivated candidates who aren't just a
                  team player, but also thrives in a fast-paced environment. If
                  you're smart, creative, and ambitious as a software engineer,
                  we'd like to talk to you. We are always looking for a better
                  solution, and in doing so, your expertise in protocol stack
                  development, web and mobile systems architecture and
                  frameworks engineering would be massively beneficial.
                </div>
              </div>
            </div> */}
          </motion.div>
        </>
      )}
    </motion.div>
  );
}

export default Careers;
