import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// CSS

import "./Privacy.scss";

// Images
import Background from "../../assets/images/careers-bg-mobile.png";
import WheelboxBG from "../../assets/images/mobile-development/wheelbox-bg.png";

const UserDataDeletion = ({ appNo, args }) => {
  const navigate = useNavigate("/");
  const [activeContainerStyles, setActiveContainerStyles] = useState(null);
  const [innerContStyles, setInnerContStyles] = useState(null);
  const [headerStyles, setHeaderStyles] = useState(null);
  const [divStyles, setDivStyles] = useState(null);
  const [appName, setAppName] = useState("");

  useEffect(() => {
    switch (appNo) {
      case 1:
        setActiveContainerStyles({
          height: args.height,
          width: args.width,
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        });
        setAppName("Health Log Kit");
        break;
      default:
        setActiveContainerStyles({
          height: args.height,
          width: args.width,
          backgroundImage: `url(${WheelboxBG})`,
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        });
        setInnerContStyles({
          background:
            "linear-gradient(0deg, rgba(24,26,32,1) 100%, rgba(69,73,85,0) 100%)",
          padding: "2%",
          borderRadius: "10px",
        });
        setHeaderStyles({
          color: "#246AFD",
          textTransform: "uppercase",
        });
        setDivStyles({
          color: "#fff",
        });
        setAppName("My Wheelbox");
        break;
    }
  }, [appNo]);
  return (
    <div className='tos-container' style={activeContainerStyles}>
      <div
        id='btnBack'
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </div>
      <div className='inner-container' style={innerContStyles}>
        <h1 style={headerStyles}>User Data Deletion</h1>
        <div style={divStyles}>
          Since {appName} does not capture any data from you, you may simply
          delete the app on your smartphone device to delete all records you
          have on this application.
        </div>
      </div>
    </div>
  );
};

export default UserDataDeletion;
