import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// CSS

import "./Privacy.scss";

// Images

import Background from "../../assets/images/careers-bg-mobile.png";
import WheelboxBG from "../../assets/images/mobile-development/wheelbox-bg.png";

const Privacy = ({ appNo, args }) => {
  const navigate = useNavigate("/");
  const [activeContainerStyles, setActiveContainerStyles] = useState(null);
  const [innerContStyles, setInnerContStyles] = useState(null);
  const [headerStyles, setHeaderStyles] = useState(null);
  const [divStyles, setDivStyles] = useState(null);
  const [appName, setAppName] = useState("");

  const HealthLogKitContent = () => {
    return (
      <>
        <h1>PRIVACY NOTICE</h1>
        <h2>Who are we?</h2>
        <div>
          This privacy policy applies to Toplogic Consulting Corporation ("us",
          "we", or "our"), which operates the Health Log Kit (the "App").
        </div>
        <div>
          This page informs you of our policies regarding the collection, use
          and disclosure of Personal Information when you use our App.
        </div>
        <div>
          <span className='underline'>We will not use or share</span> your
          information with anyone except as described in this Privacy Policy. By
          using the App, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy.
        </div>
        <div>
          By using the App, you agree to the collection and use of information
          in accordance with this policy. Unless otherwise defined in this
          Privacy Policy.
        </div>
        <h2>Privacy policy made simple</h2>
        <div>
          We are serious about the personal information you’re going to record
          on this mobile application; therefore,{" "}
          <span className='underline'>we will not collect any various</span>{" "}
          information about you when you use the Health Log Kit app. All
          information you record in this application will be stored locally on
          your smartphone device and you can use Health Log Kit on one
          smartphone only and we will help your back-up your local data on your
          preferred back-up cloud services (e.g., DropBox, iCloud or Google
          Drive).
        </div>
        <h2>Health Log Kit data only on your local smart phone device</h2>
        <ul>
          <li>
            We do not keep your data in our own storage, data being captured in
            the Health Log Kit App is only stored in your smartphone and your
            backup account (if any).
          </li>
          <li>
            We use encryption when your data is stored locally on your
            smartphone.
          </li>
          <li>
            We are using Realm (more of them at https://realm.io) local database
            engine technology and best designed for smartphones.
          </li>
          <li>
            We have provided a Send us your feedback feature (built-in to Health
            Log Kit) for us to communicate with you and respond to questions,
            comments, and feedback on how you use Health Log Kit application
          </li>
        </ul>
        <h2>Importing data from Apple HealthKit and Google Fit</h2>
        <div>
          You can import health data from other applications, such as step count
          from a fitness tracker via Apple HealthKit or Google Fit and add them
          to your Health Log Kit health records, if you technically allow Apple
          and Google to do so. For starter we are only implementing Apple
          HealthKit or Google Fit data only for this purpose.
        </div>
        <div>
          Apple HealthKit: We never use your Apple HealthKit data for
          advertising and similar services or for use-based data mining. We do
          not disclose information gained through Apple HealthKit to any third
          party or sell it to advertising platforms, data brokers, or
          information resellers.
        </div>
        <div>
          Google Fit: We do not use the content accessed from Google Fit in
          connection with any advertising, sponsorship or promotions, we do not
          share or sell that content to any data broker or information reseller,
          and we do not permit our users to do any of the above.
        </div>
        <div className='tos-footer'>
          For more details on our security and data management processes, please
          contact us.
        </div>
      </>
    );
  };

  const MyWheelboxContent = () => {
    return (
      <>
        <h1 style={headerStyles}>Privacy Notice</h1>
        <h2 style={headerStyles}>Who are we?</h2>
        <div style={divStyles}>
          This privacy policy applies to Toplogic Consulting Corporation ("us",
          "we", or "our"), which operates the My WheelBox (the "App")
        </div>
        <div style={divStyles}>
          This page informs you of our policies regarding the collection, use
          and disclosure of Personal Information when you use our App.
        </div>
        <div style={divStyles}>
          <b>We will not use or share your information</b> with anyone except as
          described in this Privacy Policy.
        </div>
        <div style={divStyles}>
          By using the App, you agree to the collection and use of information
          in accordance with this policy. Unless otherwise defined in this
          Privacy Policy.
        </div>

        <h2 style={headerStyles}>Privacy policy made simple</h2>

        <div style={divStyles}>
          We are serious about the personal information you’re going to record
          on this mobile application; therefore,{" "}
          <b>we will not collect any various information about you</b> when you
          use the My WheelBox app. All information you record in this
          application will be stored locally on your smartphone device and you
          can use My WheelBox on one smartphone only and we will help your
          back-up your local data on your preferred back-up cloud services
          (e.g., DropBox, iCloud or Google Drive).
        </div>

        <h2 style={headerStyles}>
          My WheelBox data only on your local smart phone device
        </h2>

        <ul className='my-wheelbox-list'>
          <li>
            We do not keep your data in our own storage, data being captured in
            the My WheelBox App is only stored in your smartphone and your
            backup account (if any).
          </li>
          <li>
            We use encryption when your data is stored locally on your
            smartphone.
          </li>
          <li>
            We are using Realm (more of them at https://realm.io) local database
            engine technology and best designed for smartphones.
          </li>
          <li>
            We have provided a Send us your feedback feature (built-in to My
            WheelBox) for us to communicate with you and respond to questions,
            comments, and feedback on how you use My WheelBox application
          </li>
        </ul>
      </>
    );
  };

  useEffect(() => {
    switch (appNo) {
      case 1:
        setActiveContainerStyles({
          height: args.height,
          width: args.width,
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
        });
        setAppName("Health Log Kit");
        break;
      default:
        setActiveContainerStyles({
          height: args.height,
          width: args.width,
          backgroundImage: `url(${WheelboxBG})`,
          backgroundSize: "cover",
        });
        setInnerContStyles({
          background:
            "linear-gradient(0deg, rgba(24,26,32,1) 100%, rgba(69,73,85,0) 100%)",
          padding: "2%",
          borderRadius: "10px",
        });
        setHeaderStyles({
          color: "#246AFD",
          textTransform: "uppercase",
        });
        setDivStyles({
          color: "#fff",
        });
        setAppName("My Wheelbox");
        break;
    }
  }, [appNo]);

  return (
    <div className='tos-container' style={activeContainerStyles}>
      <div
        id='btnBack'
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </div>
      <div className='inner-container' style={innerContStyles}>
        {appNo === 1 && <HealthLogKitContent />}
        {appNo === 2 && <MyWheelboxContent />}
      </div>
    </div>
  );
};

export default Privacy;
