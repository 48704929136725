import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// CSS

import "./Privacy.scss";

// Images

import Background from "../../assets/images/careers-bg-mobile.png";
import WheelboxBG from "../../assets/images/mobile-development/wheelbox-bg.png";

const TermsAndConditions = ({ appNo, args }) => {
  const navigate = useNavigate("/");
  const [activeContainerStyles, setActiveContainerStyles] = useState(null);
  const [innerContStyles, setInnerContStyles] = useState(null);
  const [headerStyles, setHeaderStyles] = useState(null);
  const [divStyles, setDivStyles] = useState(null);
  const [appName, setAppName] = useState("");

  const HealthLogKitContent = () => {
    return (
      <>
        <h1>TERMS AND CONDITIONS</h1>
        <h2>Who are we?</h2>
        <div>
          This privacy policy applies to Toplogic Consulting Corporation ("us",
          "we", or "our"), which operates the Health Log Kit (the "App")
        </div>
        <div>
          If you have questions, please contact us at consulting@toplogic.com.ph
        </div>
        <h2>1. Using Health Log Kit</h2>
        <div>
          Health Log Kit does not provide any medical advice, therapeutic or
          diagnostic information or recommendations.
        </div>
        <div>
          You may only use Health Log Kit to manage and document health.
          However, do not rely solely on Health Log Kit, e.g. for remembering
          medication. We do not check the correctness or completeness of your
          settings or entries in Health Log Kit since we do not collect any
          information from you and that your data and recorded only on your
          smartphone device.
        </div>
        <div>
          Always verify the accuracy of your content in Health Log Kit: Make
          medical decisions based on your doctor’s advice only. We do not verify
          the accuracy of third-party content in Health Log Kit.
        </div>
        <div>
          We do not verify communication between users, so be careful when you
          connect with other users, share health data or exchange messages.
        </div>
        <div>
          You may use Health Log Kit if you are of legal age or with the consent
          of your guardians. You may not use Health Log Kit for commercial or
          professional purposes.
        </div>
        <div>
          You are not permitted to use Health Log Kit on a smartphone which has
          been jailbroken or rooted. Jailbreaking and rooting removes safeguards
          from your smartphone, which can leave your smartphone vulnerable to
          fraudulent attacks.
        </div>
        <h2>2. Provision of Health Log Kit</h2>
        <div>
          Some Health Log Kit functionality requires internet connection. As we
          rely on telecommunication networks beyond our control, we cannot
          guarantee uninterrupted availability of Health Log Kit. We try to
          minimize operational interruptions e.g. for maintenance.
        </div>
        <div>
          We cannot guarantee Health Log Kit working on your smartphone or
          compatibility with your smartphone’s software or hardware. We do not
          have to provide technical support or maintenance for Health Log Kit.
        </div>
        <div>
          We are not liable for data loss if you delete your data, delete Health
          Log Kit from your smartphone, delete your account or if you lose
          access to your account.
        </div>
        <h2>3. Registering an account</h2>
        <div>
          You can use Health Log Kit by registering to your Facebook, Google or
          Apple ID account and you can only use Health Log Kit on one smartphone
          where you will record your data locally and encrypted. If you lose
          this smartphone, you will need to restore your data from the Cloud
          services drive where you have created your back-up. You are
          responsible for making sure that you back-up your data regularly and
          keeping your password confidential.
        </div>
        <h2>4. Right to use Health Log Kit</h2>
        <div>
          We grant you a limited, non-exclusive, non-transferable,
          non-sublicensable right to use Health Log Kit for the purpose and
          duration of the agreement based on these terms (“this agreement”). You
          may download a copy of Health Log Kit on your smartphone as necessary
          for using Health Log Kit only. You may not make Health Log Kit
          publicly accessible, rent out or otherwise commercially use Health Log
          Kit. All rights not explicitly granted remain reserved.
        </div>
        <div>
          You may use included databases only as required for the purpose of
          using Health Log Kit.
        </div>
        <h2>5. Duration and termination</h2>
        <div>
          This agreement is concluded for an indefinite period. You may
          terminate this agreement anytime.
        </div>
        <div>
          You cancel the agreement by deleting Health Log Kit on your smartphone
          device.
        </div>
        <div>The right of termination for good cause remains unaffected.</div>
        <h2>Amendments</h2>
        <div>
          We reserve the right to amend these terms and will inform you of
          changes in time. By continuing to use Health Log Kit after a change,
          you agree to follow and be bound by the updated terms. When we inform
          you of changed terms, we will remind you again that continued use of
          Health Log Kit implies agreement to the terms. These terms were last
          changed on 03.01.2022.
        </div>
      </>
    );
  };

  const MyWheelboxContent = () => {
    return (
      <>
        <h1 style={headerStyles}>Terms of Use</h1>
        <h2 style={headerStyles}>Who are we?</h2>
        <div style={divStyles}>
          This privacy policy applies to Toplogic Consulting Corporation ("us",
          "we", or "our"), which operates the My WheelBox (the "App") If you
          have questions, please contact us at consulting@toplogic.com.ph
        </div>
        <h2 style={headerStyles}>1. Using My Wheelbox</h2>
        <div style={divStyles}>
          You may use My WheelBox to manage and document your vehicles
          performance data, Insurance and PMS. However, do not rely solely on My
          Wheelbox (e.g. for remembering your next PMS or your Insurance
          renewal. We do not check the correctness or completeness of your
          entries in My WheelBox since we do not collect any information from
          you and that your data and recorded only on your smartphone device.
        </div>
        <div style={divStyles}>
          We do not verify communication between users, so be careful when you
          connect with other users, share your vehicle data or exchange
          messages.
        </div>
        <div style={divStyles}>
          You are not permitted to use My WheelBox on a smartphone which has
          been jailbroken or rooted. Jailbreaking and rooting removes safeguards
          from your smartphone, which can leave your smartphone vulnerable to
          fraudulent attacks.
        </div>
        <h2 style={headerStyles}>2. Provision of My WheelBox</h2>
        <div style={divStyles}>
          Some My WheelBox functionality requires internet connection. As we
          rely on telecommunication networks beyond our control, we cannot
          guarantee uninterrupted availability of My WheelBox. We try to
          minimize operational interruptions e.g. for maintenance.
        </div>
        <div style={divStyles}>
          We cannot guarantee My WheelBox working on your smartphone or
          compatibility with your smartphone’s software or hardware. We do not
          have to provide technical support or maintenance for My WheelBox.
        </div>
        <div style={divStyles}>
          We are not liable for data loss if you delete your data, delete My
          WheelBox from your smartphone, delete your account or if you lose
          access to your account.
        </div>
        <h2 style={headerStyles}>3. Registering an Account</h2>
        <div style={divStyles}>
          You can use My WheelBox by registering to your Facebook, Google or
          Apple ID account and you can only use My WheelBox on one smartphone
          where you will record your data locally and encrypted. If you lose
          this smartphone, you will need to restore your data from the Cloud
          services drive where you have created your back-up. You are
          responsible for making sure that you back-up your data regularly and
          keeping your password confidential.
        </div>
        <h2 style={headerStyles}>4. Right to use My WheelBox</h2>
        <div style={divStyles}>
          We grant you a limited, non-exclusive, non-transferable,
          non-sublicensable right to use My WheelBox for the purpose and
          duration of the agreement based on these terms (“this agreement”). You
          may download a copy of My WheelBox on your smartphone as necessary for
          using My WheelBox only. You may not make My WheelBox publicly
          accessible, rent out or otherwise commercially use My WheelBox. All
          rights not explicitly granted remain reserved.
        </div>
        <div style={divStyles}>
          You may use included databases only as required for the purpose of
          using My WheelBox.
        </div>
        <h2 style={headerStyles}>5. Duration and termination</h2>
        <div style={divStyles}>
          This agreement is concluded for an indefinite period. You may
          terminate this agreement anytime.
        </div>
        <div style={divStyles}>
          You cancel the agreement by deleting My WheelBox on your smartphone
          device.
        </div>
        <div style={divStyles}>
          The right of termination for good cause remains unaffected.
        </div>
        <h2 style={headerStyles}>6. Amendments</h2>
        <div style={divStyles}>
          We reserve the right to amend these terms and will inform you of
          changes in time. By continuing to use My WheelBox after a change, you
          agree to follow and be bound by the updated terms. When we inform you
          of changed terms, we will remind you again that continued use of My
          WheelBox implies agreement to the terms. These terms were last changed
          on 03.01.2022.
        </div>
      </>
    );
  };

  useEffect(() => {
    switch (appNo) {
      case 1:
        setActiveContainerStyles({
          height: args.height,
          width: args.width,
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
        });
        setAppName("Health Log Kit");
        break;
      default:
        setActiveContainerStyles({
          height: args.height,
          width: args.width,
          backgroundImage: `url(${WheelboxBG})`,
          backgroundSize: "cover",
        });
        setInnerContStyles({
          background:
            "linear-gradient(0deg, rgba(24,26,32,1) 100%, rgba(69,73,85,0) 100%)",
          padding: "2%",
          borderRadius: "10px",
        });
        setHeaderStyles({
          color: "#246AFD",
          textTransform: "uppercase",
        });
        setDivStyles({
          color: "#fff",
        });
        setAppName("My Wheelbox");
        break;
    }
  }, [appNo]);
  return (
    <div className='tos-container' style={activeContainerStyles}>
      <div
        id='btnBack'
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </div>
      <div className='inner-container' style={innerContStyles}>
        {appNo === 1 && <HealthLogKitContent />}
        {appNo === 2 && <MyWheelboxContent />}
      </div>
    </div>
  );
};

export default TermsAndConditions;
