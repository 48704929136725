import React, { useState } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import App from "./App";

// Components
import Privacy from "./components/pages/Privacy";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import ErrorPage from "./components/pages/ErrorPage";
import UserDataDeletion from "./components/pages/UserDataDeletion";

const Router = () => {
  const [height, setHeight] = useState(window.height);
  const [width, setWidth] = useState(window.width);
  return (
    <HashRouter>
      <Routes>
        <Route
          exact
          path='/health-log-kit/privacy-notice'
          element={
            <Privacy name='privacy-notice' args={{ height, width }} appNo={1} />
          }
        />
        <Route
          exact
          path='/health-log-kit/terms-and-conditions'
          element={
            <TermsAndConditions
              name='terms-and-conditions'
              args={{ height, width }}
              appNo={1}
            />
          }
        />
        <Route
          exact
          path='/health-log-kit/user-data-deletion'
          element={
            <UserDataDeletion
              name='user-data-deletion'
              args={{ height, width }}
              appNo={1}
            />
          }
        />
        <Route
          exact
          path='/my-wheelbox/privacy-notice'
          element={
            <Privacy name='privacy-notice' args={{ height, width }} appNo={2} />
          }
        />
        <Route
          exact
          path='/my-wheelbox/terms-and-conditions'
          element={
            <TermsAndConditions
              name='terms-and-conditions'
              args={{ height, width }}
              appNo={2}
            />
          }
        />
        <Route
          exact
          path='/my-wheelbox/user-data-deletion'
          element={
            <UserDataDeletion
              name='user-data-deletion'
              args={{ height, width }}
              appNo={2}
            />
          }
        />
        <Route exact path='/' element={<App />} />
        <Route exact path='*' element={<ErrorPage />} />
      </Routes>
    </HashRouter>
  );
};

export default Router;
