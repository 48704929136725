import React from "react";

import "./MapsModal.scss";

import { FaTimes } from "react-icons/fa";

const MapsModal = ({ ...props }) => {
  return (
    <>
      {props.isMapsActive && (
        <div className='maps_modal_main'>
          <div className='maps_inner'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3864.1439525243154!2d121.03239682885506!3d14.418863953138375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397d10aade6c6cb%3A0xbe5edf30267aef59!2sKMC%20%7C%20One%20Griffinstone%2C%20Coworking%20Space%2C%20Alabang!5e0!3m2!1sen!2sph!4v1670218296381!5m2!1sen!2sph'
              width='100%'
              height='100%'
              style={{
                border: 0,
              }}
              allowfullscreen=''
              loading='lazy'
              referrerpolicy='no-referrer-when-downgrade'
            ></iframe>
          </div>
          <div
            id='btnBack'
            style={{
              left: "50%",
              transform: "translate(-50%, -100%)",
              position: "absolute",
              borderRadius: "10px 10px 0px 0px",
              background: "var(--primary-gradient)",
              bottom: "0%",
              top: "100%",
            }}
            onClick={() => {
              props.setIsMapsActive(!props.isMapsActive);
            }}
          >
            <FaTimes />
          </div>
        </div>
      )}
    </>
  );
};

export default MapsModal;
