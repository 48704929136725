import React, { useState, useEffect, lazy, Suspense } from "react";
import { useMediaQuery } from "react-responsive";

import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import Marlon from "../../assets/images/team/1.jpeg";
import Maureen from "../../assets/images/team/2.jpeg";
import Paula from "../../assets/images/team/3.png";
import Elaine from "../../assets/images/team/4.jpeg";
import Chat from "../../assets/images/team/5.jpeg";
import Marsha from "../../assets/images/team/6.png";
import Rhodgie from "../../assets/images/team/7.jpeg";
import Jhonas from "../../assets/images/team/8.jpeg";
import Mike from "../../assets/images/team/MCM.png";
import Gab from "../../assets/images/team/10.png";
// import Mich from "../../assets/images/team/11.png";
// import Sheryll from "../../assets/images/team/sheryll.png";
import JC from "../../assets/images/team/jco.jpg";
import Evan from "../../assets/images/team/12.jpeg";
import CEO from "../../assets/images/team/ceo.jpeg";
import CEO_Mobile from "../../assets/images/team/ceo--mobile.png";
import BG_Mobile from "../../assets/images/team/about_bg--mobile.png";
import MobileBackdrop from "../../assets/images/toplogic-logo-home.png";

import "./About.scss";

function About() {
  const width = window.innerWidth;
  const [shouldShowActions, setShouldShowActions] = useState(0);
  const [lastYPos, setLastYPos] = useState(0);
  const [click, setClick] = useState(false);
  const [isCEOShown, setIsCEOShown] = useState(true);
  const [title, setTitle] = useState("Founder and CEO");
  const [name, setName] = useState("Marlon Estrelon");
  const [index, setIndex] = useState(1);
  const [hoverInd, setHoverInd] = useState(0);

  const handleHover = (int) => {
    setHoverInd(int);
  };

  const handleClick = (e) => {
    setClick(!click);

    if (e.target.id == "btnOurCEO") {
      setIsCEOShown(true);
      setShouldShowActions(false);
      document.getElementById("btnOurCEO").classList.add("active");
      document.getElementById("btnOurTeam").classList.remove("active");
    } else {
      setIsCEOShown(false);
      setShouldShowActions(true);
      document.getElementById("btnOurCEO").classList.remove("active");
      document.getElementById("btnOurTeam").classList.add("active");
    }

    if (e.target.id == "btnOurTeam") {
      setIsCEOShown(false);
      setShouldShowActions(true);
      document.getElementById("btnOurCEO").classList.remove("active");
      document.getElementById("btnOurTeam").classList.add("active");
    } else {
      setIsCEOShown(true);
      setShouldShowActions(false);
      document.getElementById("btnOurCEO").classList.add("active");
      document.getElementById("btnOurTeam").classList.remove("active");
    }
  };

  const { ref, inView } = useInView();

  // useEffect(() => {
  //   if (inView) {
  //     setIsCEOShown(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   function handleScroll() {
  //     const yPos = window.scrollY;

  //     if (
  //       !isCEOShown ||
  //       !document.getElementById("btnOurCEO").classList.contains("active")
  //     ) {
  //       setShouldShowActions(true);
  //       setIsCEOShown(false);
  //     } else if (
  //       !shouldShowActions ||
  //       !document.getElementById("btnOurTeam").classList.contains("active")
  //     ) {
  //       setShouldShowActions(false);
  //       setIsCEOShown(true);
  //     }
  //     setLastYPos(yPos);
  //   }
  //   window.addEventListener("scroll", handleScroll, false);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll, false);
  //   };
  // }, [lastYPos]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 960px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  const handleTeamClick = (index) => {
    setIndex(index);

    switch (index) {
      case 1:
        setName("Marlon Estrelon");
        setTitle("Founder and CEO");
        break;
      case 2:
        setName("Maureen Estrelon");
        setTitle("Founder and CFO");
        break;
      case 3:
        setName("Paula Punzalan");
        setTitle("Chief Information Officer");
        break;
      case 4:
        setName("Elaine Villaluz");
        setTitle("Head of Business Analysis");
        break;
      case 5:
        setName("Charito Espiritu");
        setTitle("Head of Support and Services");
        break;
      case 6:
        setName("Christine Marsha Piojo");
        setTitle("Head of Unit Testing and QA");
        break;
      case 7:
        setName("Rhodgie Cangco");
        setTitle("Head of Backroom Development");
        break;
      case 8:
        setName("Jhonas Abing");
        setTitle("Head of Mobile Development");
        break;
      case 9:
        setName("Mike Macalinao");
        setTitle("Head of System Automation");
        break;
      case 10:
        setName("Gabriel Kyle Itgano");
        setTitle("Head of Research and Development");
        break;
      case 11:
        setName("JC Onciangco");
        setTitle("Head of Web Development");
        break;
      case 12:
        setName("Evan Bruma");
        setTitle("Head of Creatives and Design");
        break;
      default:
        return;
    }
  };

  return (
    <Suspense fallback={<p>Loading...</p>}>
      <motion.div
        className="about"
        // ref={ref}
        style={{
          position: "relative",
          height: isTabletOrMobile
            ? window.innerHeight - 100
            : window.innerHeight - 50,
          background: isTabletOrMobile ? "#1f1f1f" : "var(--l-grey)",
        }}
      >
        {isDesktopOrLaptop && (
          <>
            <motion.div
              className="about__ceo"
              initial={{ translateX: width * 2, opacity: 0 }}
              animate={{
                translateX: isCEOShown ? 0 : width * 2,
                opacity: isCEOShown ? 1 : 0,
              }}
              transition={{
                ease: [0.6, 0.01, -0.05, 0.95],
                duration: 1.8,
                delay: 0,
              }}
              style={{
                position: "absolute",
                backgroundImage: `url(${MobileBackdrop})`,
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="ceo__image-container">
                <img src={CEO}></img>
              </div>
              <div className="ceo__text-container">
                <div className="ceo__name">Marlon Estrelon</div>
                <div className="ceo__title">Founder and CEO</div>
                <div className="ceo__desc">
                  We believe as strongly today, as we did then, that building a
                  healthy relationship with our clients will result to powerful
                  experiences that will help move us forward. TopLogic has been
                  around since 2007 and we aim to deliver both packaged and
                  tailored-fit software products and services to small, medium
                  and large scale businesses.
                </div>
                <div className="ceo__desc">
                  Over the years we were able to build a solid team of IT
                  professionals who came from various IT backgrounds to expand
                  the creative potential of the company. Using the latest
                  techniques in software development and by using advanced
                  software development tools, we were able to establish the
                  right grounds that we need in order to be successful in this
                  industry and position ourselves to where we are today.
                </div>
              </div>
            </motion.div>
            <motion.div
              className="about__row row-2"
              initial={{ translateX: -width }}
              animate={{
                translateX: shouldShowActions ? 0 : -width,
              }}
              transition={{
                ease: [0.6, 0.01, -0.05, 0.95],
                duration: 1.8,
                delay: 0,
              }}
            >
              <motion.div
                className="member__container"
                initial={{ rotateY: 40, opacity: 0 }}
                animate={{
                  rotateY: shouldShowActions ? 0 : 40,
                  opacity: shouldShowActions ? 1 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: shouldShowActions ? 2 : 0.2,
                  delay: shouldShowActions ? 0 : 0,
                }}
                onMouseOver={() => handleHover(1)}
                onMouseLeave={() => setHoverInd(0)}
              >
                <div className="member__container-image">
                  <img src={Marlon}></img>
                </div>
                <motion.div
                  className="member__container-name"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 1 ? 1 : 0,
                    translateY: hoverInd === 1 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                  }}
                >
                  Marlon Estrelon
                </motion.div>
                <motion.div
                  className="member__container-title"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 1 ? 1 : 0,
                    translateY: hoverInd === 1 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                  }}
                >
                  Founder and CEO
                </motion.div>
              </motion.div>
              <motion.div
                className="member__container"
                initial={{ rotateY: 40, opacity: 0 }}
                animate={{
                  rotateY: shouldShowActions ? 0 : 40,
                  opacity: shouldShowActions ? 1 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: shouldShowActions ? 2 : 0.2,
                  delay: shouldShowActions ? 0.2 : 0,
                }}
                onMouseOver={() => handleHover(2)}
                onMouseLeave={() => setHoverInd(0)}
              >
                <div className="member__container-image">
                  <img src={Maureen}></img>
                </div>
                <motion.div
                  className="member__container-name"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 2 ? 1 : 0,
                    translateY: hoverInd === 2 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                  }}
                >
                  Maureen Estrelon
                </motion.div>
                <motion.div
                  className="member__container-title"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 2 ? 1 : 0,
                    translateY: hoverInd === 2 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                  }}
                >
                  Founder and CFO
                </motion.div>
              </motion.div>
              <motion.div
                className="member__container"
                initial={{ rotateY: 40, opacity: 0 }}
                animate={{
                  rotateY: shouldShowActions ? 0 : 40,
                  opacity: shouldShowActions ? 1 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: shouldShowActions ? 2 : 0.2,
                  delay: shouldShowActions ? 0.4 : 0,
                }}
                onMouseOver={() => handleHover(3)}
                onMouseLeave={() => setHoverInd(0)}
              >
                <div className="member__container-image">
                  <img src={Paula} style={{ mixBlendMode: "lighten" }}></img>
                </div>
                <motion.div
                  className="member__container-name"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 3 ? 1 : 0,
                    translateY: hoverInd === 3 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                  }}
                >
                  Paula Punzalan
                </motion.div>
                <motion.div
                  className="member__container-title"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 3 ? 1 : 0,
                    translateY: hoverInd === 3 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                  }}
                >
                  Chief Information Officer
                </motion.div>
              </motion.div>
              <motion.div
                className="member__container"
                initial={{ rotateY: 40, opacity: 0 }}
                animate={{
                  rotateY: shouldShowActions ? 0 : 40,
                  opacity: shouldShowActions ? 1 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: shouldShowActions ? 2 : 0.2,
                  delay: shouldShowActions ? 0.6 : 0,
                }}
                onMouseOver={() => handleHover(4)}
                onMouseLeave={() => setHoverInd(0)}
              >
                <div className="member__container-image">
                  <img src={Elaine}></img>
                </div>
                <motion.div
                  className="member__container-name"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 4 ? 1 : 0,
                    translateY: hoverInd === 4 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                  }}
                >
                  Elaine Villaluz
                </motion.div>
                <motion.div
                  className="member__container-title"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 4 ? 1 : 0,
                    translateY: hoverInd === 4 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                  }}
                >
                  Head of Business Analysis
                </motion.div>
              </motion.div>
              <motion.div
                className="member__container"
                initial={{ rotateY: 40, opacity: 0 }}
                animate={{
                  rotateY: shouldShowActions ? 0 : 40,
                  opacity: shouldShowActions ? 1 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: shouldShowActions ? 2 : 0.2,
                  delay: shouldShowActions ? 0.8 : 0,
                }}
                onMouseOver={() => handleHover(5)}
                onMouseLeave={() => setHoverInd(0)}
              >
                <div className="member__container-image">
                  <img src={Chat}></img>
                </div>
                <motion.div
                  className="member__container-name"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 5 ? 1 : 0,
                    translateY: hoverInd === 5 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                  }}
                >
                  Charito Espiritu
                </motion.div>
                <motion.div
                  className="member__container-title"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 5 ? 1 : 0,
                    translateY: hoverInd === 5 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                  }}
                >
                  Head of Support and Services
                </motion.div>
              </motion.div>
              <motion.div
                className="member__container"
                initial={{ rotateY: 40, opacity: 0 }}
                animate={{
                  rotateY: shouldShowActions ? 0 : 40,
                  opacity: shouldShowActions ? 1 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: shouldShowActions ? 2 : 0.2,
                  delay: shouldShowActions ? 1 : 0,
                }}
                onMouseOver={() => handleHover(6)}
                onMouseLeave={() => setHoverInd(0)}
              >
                <div className="member__container-image">
                  <img src={Marsha} style={{ mixBlendMode: "lighten" }}></img>
                </div>
                <motion.div
                  className="member__container-name"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 6 ? 1 : 0,
                    translateY: hoverInd === 6 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                  }}
                >
                  Christine Marsha Piojo
                </motion.div>
                <motion.div
                  className="member__container-title"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 6 ? 1 : 0,
                    translateY: hoverInd === 6 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                  }}
                >
                  Head of Unit Testing and QA
                </motion.div>
              </motion.div>
            </motion.div>
            <motion.div
              className="about__row row-3"
              initial={{ translateX: -width }}
              animate={{
                translateX: shouldShowActions ? 0 : -width,
              }}
              transition={{
                ease: [0.6, 0.01, -0.05, 0.95],
                duration: 1.8,
                delay: 0,
              }}
            >
              <motion.div
                className="member__container"
                initial={{ rotateY: 40, opacity: 0 }}
                animate={{
                  rotateY: shouldShowActions ? 0 : 40,
                  opacity: shouldShowActions ? 1 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: shouldShowActions ? 2 : 0.2,
                  delay: shouldShowActions ? 1.2 : 0,
                }}
                onMouseOver={() => handleHover(7)}
                onMouseLeave={() => setHoverInd(0)}
              >
                <div className="member__container-image">
                  <img src={Rhodgie}></img>
                </div>
                <motion.div
                  className="member__container-name"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 7 ? 1 : 0,
                    translateY: hoverInd === 7 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                  }}
                >
                  Rhodgie Cangco
                </motion.div>
                <motion.div
                  className="member__container-title"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 7 ? 1 : 0,
                    translateY: hoverInd === 7 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                  }}
                >
                  Head of Backroom Development
                </motion.div>
              </motion.div>

              <motion.div
                className="member__container"
                initial={{ rotateY: 40, opacity: 0 }}
                animate={{
                  rotateY: shouldShowActions ? 0 : 40,
                  opacity: shouldShowActions ? 1 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: shouldShowActions ? 2 : 0.2,
                  delay: shouldShowActions ? 1.4 : 0,
                }}
                onMouseOver={() => handleHover(8)}
                onMouseLeave={() => setHoverInd(0)}
              >
                <div className="member__container-image">
                  <img src={Jhonas}></img>
                </div>
                <motion.div
                  className="member__container-name"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 8 ? 1 : 0,
                    translateY: hoverInd === 8 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                  }}
                >
                  Jhonas Abing
                </motion.div>
                <motion.div
                  className="member__container-title"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 8 ? 1 : 0,
                    translateY: hoverInd === 8 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                  }}
                >
                  Head of Mobile Development
                </motion.div>
              </motion.div>
              <motion.div
                className="member__container"
                initial={{ rotateY: 40, opacity: 0 }}
                animate={{
                  rotateY: shouldShowActions ? 0 : 40,
                  opacity: shouldShowActions ? 1 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: shouldShowActions ? 2 : 0.2,
                  delay: shouldShowActions ? 1.6 : 0,
                }}
                onMouseOver={() => handleHover(9)}
                onMouseLeave={() => setHoverInd(0)}
              >
                <div className="member__container-image">
                  <img src={Mike} style={{ mixBlendMode: "lighten" }}></img>
                </div>
                <motion.div
                  className="member__container-name"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 9 ? 1 : 0,
                    translateY: hoverInd === 9 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                  }}
                >
                  Mike Macalinao
                </motion.div>
                <motion.div
                  className="member__container-title"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 9 ? 1 : 0,
                    translateY: hoverInd === 9 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                  }}
                >
                  Head of System Automation
                </motion.div>
              </motion.div>
              <motion.div
                className="member__container"
                initial={{ rotateY: 40, opacity: 0 }}
                animate={{
                  rotateY: shouldShowActions ? 0 : 40,
                  opacity: shouldShowActions ? 1 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: shouldShowActions ? 2 : 0.2,
                  delay: shouldShowActions ? 1.8 : 0,
                }}
                onMouseOver={() => handleHover(10)}
                onMouseLeave={() => setHoverInd(0)}
              >
                <div className="member__container-image">
                  <img src={Gab} style={{ mixBlendMode: "lighten" }}></img>
                </div>
                <motion.div
                  className="member__container-name"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 10 ? 1 : 0,
                    translateY: hoverInd === 10 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                  }}
                >
                  Gabriel Kyle Itgano
                </motion.div>
                <motion.div
                  className="member__container-title"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 10 ? 1 : 0,
                    translateY: hoverInd === 10 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                  }}
                >
                  Head of Research and Development
                </motion.div>
              </motion.div>
              <motion.div
                className="member__container"
                initial={{ rotateY: 40, opacity: 0 }}
                animate={{
                  rotateY: shouldShowActions ? 0 : 40,
                  opacity: shouldShowActions ? 1 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: shouldShowActions ? 2 : 0.2,
                  delay: shouldShowActions ? 2 : 0,
                }}
                onMouseOver={() => handleHover(11)}
                onMouseLeave={() => setHoverInd(0)}
              >
                <div className="member__container-image">
                  <img
                    src={JC}
                    style={{
                      mixBlendMode: "lighten",
                      height: "80%",
                      position: "relative",
                      top: "45%",
                    }}
                  ></img>
                </div>
                <motion.div
                  className="member__container-name"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                    position: "relative",
                    top: "-10%",
                  }}
                  animate={{
                    opacity: hoverInd === 11 ? 1 : 0,
                    translateY: hoverInd === 11 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                  }}
                >
                  JC Onciangco
                </motion.div>
                <motion.div
                  className="member__container-title"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                    position: "relative",
                    top: "-10%",
                  }}
                  animate={{
                    opacity: hoverInd === 11 ? 1 : 0,
                    translateY: hoverInd === 11 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                  }}
                >
                  Head of Web Development
                </motion.div>
              </motion.div>
              <motion.div
                className="member__container"
                initial={{ rotateY: 40, opacity: 0 }}
                animate={{
                  rotateY: shouldShowActions ? 0 : 40,
                  opacity: shouldShowActions ? 1 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: shouldShowActions ? 2 : 0.2,
                  delay: shouldShowActions ? 2.2 : 0,
                }}
                onMouseOver={() => handleHover(12)}
                onMouseLeave={() => setHoverInd(0)}
              >
                <div className="member__container-image">
                  <img src={Evan}></img>
                </div>
                <motion.div
                  className="member__container-name"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 12 ? 1 : 0,
                    translateY: hoverInd === 12 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                  }}
                >
                  Evan Bruma
                </motion.div>
                <motion.div
                  className="member__container-title"
                  initial={{
                    opacity: 0,
                    translateY: "-50%",
                  }}
                  animate={{
                    opacity: hoverInd === 12 ? 1 : 0,
                    translateY: hoverInd === 12 ? "0%" : "-50%",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                  }}
                >
                  Head of Creative and Design
                </motion.div>
              </motion.div>
            </motion.div>
            <div
              className={`${handleClick ? "" : "active"} about-btn`}
              onClick={handleClick}
              id="btnOurCEO"
            >
              Our CEO
            </div>
            <div
              className={`${handleClick ? "" : "active"} about-btn`}
              onClick={handleClick}
              id="btnOurTeam"
            >
              Our Team
            </div>
          </>
        )}
        {isTabletOrMobile && (
          <>
            <motion.div
              className="page-indicator"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "2.5%",
                zIndex: 3,
              }}
            >
              <motion.div
                className="indicator-1"
                initial={{
                  opacity: 0.5,
                }}
                animate={{
                  opacity: isCEOShown ? 1 : 0.5,
                }}
                style={{
                  height: "2.5px",
                  width: "50px",
                  borderRadius: "100px",
                  marginRight: "1%",
                  backgroundColor: "var(--white)",
                }}
              ></motion.div>
              <motion.div
                className="indicator-2"
                initial={{
                  opacity: 0.5,
                }}
                animate={{
                  opacity: !isCEOShown ? 1 : 0.5,
                }}
                style={{
                  height: "2.5px",
                  width: "50px",
                  borderRadius: "100px",
                  marginLeft: "1%",
                  backgroundColor: "var(--white)",
                }}
              ></motion.div>
            </motion.div>
            <motion.div
              className="about__ceo"
              initial={{
                translateX: "150%",
                zIndex: -1,
              }}
              animate={{
                translateX: "-50%",
                opacity: isCEOShown ? 1 : 0,
                zIndex: isCEOShown ? 0 : -1,
              }}
              transition={{
                ease: [0.6, 0.01, -0.05, 0.95],
                duration: 1.8,
                delay: 0,
              }}
              style={{
                position: "absolute",
                translateX: "-50%",
                translateY: "-50%",
                top: "50%",
                left: "50%",
                height: "100%",
                width: "100%",
                padding: "10%",
                backgroundImage: isTabletOrMobile
                  ? `url(${BG_Mobile})`
                  : "none",
              }}
            >
              <div
                className="ceo__image-container"
                style={{
                  height: "30%",
                  width: "100%",
                  mixBlendMode: "lighten",
                  maxWidth: "70%",
                }}
              >
                <img
                  src={CEO_Mobile}
                  style={{
                    borderRadius: "none",
                    boxShadow: "none",
                  }}
                ></img>
                <div
                  className="ceo__name-title--container"
                  style={{
                    margin: "10%",
                  }}
                >
                  <div
                    className="ceo__name"
                    style={{
                      fontSize: "24px",
                      color: "var(--primary)",
                    }}
                  >
                    Marlon Estrelon
                  </div>
                  <div
                    className="ceo__title"
                    style={{
                      fontSize: "16px",
                      textTransform: "uppercase",
                      letterSpacing: "3px",
                      color: "var(--white)",
                      borderBottom: "none",
                    }}
                  >
                    Founder and CEO
                  </div>
                </div>
              </div>
              <div
                className="ceo__text-container"
                style={{
                  marginTop: "2.5%",
                  paddingTop: "2.5%",
                  borderTop: "1px solid var(--primary)",
                  overflowY: "auto",
                }}
              >
                <div
                  className="ceo__desc"
                  style={{
                    color: "var(--white)",
                  }}
                >
                  We believe as strongly today, as we did then, that building a
                  healthy relationship with our clients will result to powerful
                  experiences that will help move us forward. TopLogic has been
                  around since 2007 and we aim to deliver both packaged and
                  tailored-fit software products and services to small, medium
                  and large scale businesses.
                </div>
                <div
                  className="ceo__desc"
                  style={{
                    color: "var(--white)",
                  }}
                >
                  Over the years we were able to build a solid team of IT
                  professionals who came from various IT backgrounds to expand
                  the creative potential of the company. Using the latest
                  techniques in software development and by using advanced
                  software development tools, we were able to establish the
                  right grounds that we need in order to be successful in this
                  industry and position ourselves to where we are today.
                </div>
              </div>
            </motion.div>
            <motion.div
              className="about__row row-2"
              initial={{ translateX: -width }}
              animate={{
                translateX: shouldShowActions ? 0 : -width,
              }}
              transition={{
                ease: [0.6, 0.01, -0.05, 0.95],
                duration: 1.8,
                delay: 0,
              }}
            >
              <motion.div
                className="member__container--row"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <motion.div
                  className="member__container"
                  onClick={() => handleTeamClick(1)}
                  initial={{ rotateY: 40, opacity: 0 }}
                  animate={{
                    rotateY: shouldShowActions ? 0 : 40,
                    opacity: shouldShowActions ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: shouldShowActions ? 2 : 0.2,
                    delay: shouldShowActions ? 0 : 0,
                  }}
                >
                  <motion.div className="member__container-image">
                    <motion.img
                      src={Marlon}
                      initial={{
                        opacity: 0.5,
                      }}
                      animate={{
                        opacity: index === 1 ? 1 : 0.5,
                      }}
                    ></motion.img>
                  </motion.div>
                </motion.div>
                <motion.div
                  className="member__container"
                  onClick={() => handleTeamClick(2)}
                  initial={{ rotateY: 40, opacity: 0 }}
                  animate={{
                    rotateY: shouldShowActions ? 0 : 40,
                    opacity: shouldShowActions ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: shouldShowActions ? 2 : 0.2,
                    delay: shouldShowActions ? 0.2 : 0,
                  }}
                >
                  <motion.div className="member__container-image">
                    <motion.img
                      src={Maureen}
                      initial={{
                        opacity: 0.5,
                      }}
                      animate={{
                        opacity: index === 2 ? 1 : 0.5,
                      }}
                    ></motion.img>
                  </motion.div>
                </motion.div>
                <motion.div
                  className="member__container"
                  onClick={() => handleTeamClick(3)}
                  initial={{ rotateY: 40, opacity: 0 }}
                  animate={{
                    rotateY: shouldShowActions ? 0 : 40,
                    opacity: shouldShowActions ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: shouldShowActions ? 2 : 0.2,
                    delay: shouldShowActions ? 0.4 : 0,
                  }}
                >
                  <motion.div className="member__container-image">
                    <motion.img
                      src={Paula}
                      initial={{
                        opacity: 0.5,
                      }}
                      animate={{
                        opacity: index === 3 ? 1 : 0.5,
                      }}
                    ></motion.img>
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
            <motion.div
              className="about__row row-3"
              initial={{ translateX: -width }}
              animate={{
                translateX: shouldShowActions ? 0 : -width,
              }}
              transition={{
                ease: [0.6, 0.01, -0.05, 0.95],
                duration: 1.8,
                delay: 0,
              }}
            >
              <motion.div
                className="member__container--row"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <motion.div
                  className="member__container"
                  onClick={() => handleTeamClick(4)}
                  initial={{ rotateY: 40, opacity: 0 }}
                  animate={{
                    rotateY: shouldShowActions ? 0 : 40,
                    opacity: shouldShowActions ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: shouldShowActions ? 2 : 0.2,
                    delay: shouldShowActions ? 0.6 : 0,
                  }}
                >
                  <motion.div className="member__container-image">
                    <motion.img
                      src={Elaine}
                      initial={{
                        opacity: 0.5,
                      }}
                      animate={{
                        opacity: index === 4 ? 1 : 0.5,
                      }}
                    ></motion.img>
                  </motion.div>
                </motion.div>
                <motion.div
                  className="member__container"
                  onClick={() => handleTeamClick(5)}
                  initial={{ rotateY: 40, opacity: 0 }}
                  animate={{
                    rotateY: shouldShowActions ? 0 : 40,
                    opacity: shouldShowActions ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: shouldShowActions ? 2 : 0.2,
                    delay: shouldShowActions ? 0.8 : 0,
                  }}
                >
                  <motion.div className="member__container-image">
                    <motion.img
                      src={Chat}
                      initial={{
                        opacity: 0.5,
                      }}
                      animate={{
                        opacity: index === 5 ? 1 : 0.5,
                      }}
                    ></motion.img>
                  </motion.div>
                </motion.div>
                <motion.div
                  className="member__container"
                  onClick={() => handleTeamClick(6)}
                  initial={{ rotateY: 40, opacity: 0 }}
                  animate={{
                    rotateY: shouldShowActions ? 0 : 40,
                    opacity: shouldShowActions ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: shouldShowActions ? 2 : 0.2,
                    delay: shouldShowActions ? 1 : 0,
                  }}
                >
                  <motion.div className="member__container-image">
                    <motion.img
                      src={Marsha}
                      initial={{
                        opacity: 0.5,
                      }}
                      animate={{
                        opacity: index === 6 ? 1 : 0.5,
                      }}
                    ></motion.img>
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
            <motion.div
              className="about__row row-4"
              initial={{ translateX: -width }}
              animate={{
                translateX: shouldShowActions ? 0 : -width,
              }}
              transition={{
                ease: [0.6, 0.01, -0.05, 0.95],
                duration: 1.8,
                delay: 0,
              }}
            >
              <motion.div
                className="member__container--row"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <motion.div
                  className="member__container"
                  onClick={() => handleTeamClick(7)}
                  initial={{ rotateY: 40, opacity: 0 }}
                  animate={{
                    rotateY: shouldShowActions ? 0 : 40,
                    opacity: shouldShowActions ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: shouldShowActions ? 2 : 0.2,
                    delay: shouldShowActions ? 1.2 : 0,
                  }}
                >
                  <motion.div className="member__container-image">
                    <motion.img
                      src={Rhodgie}
                      initial={{
                        opacity: 0.5,
                      }}
                      animate={{
                        opacity: index === 7 ? 1 : 0.5,
                      }}
                    ></motion.img>
                  </motion.div>
                </motion.div>

                <motion.div
                  className="member__container"
                  onClick={() => handleTeamClick(8)}
                  initial={{ rotateY: 40, opacity: 0 }}
                  animate={{
                    rotateY: shouldShowActions ? 0 : 40,
                    opacity: shouldShowActions ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: shouldShowActions ? 2 : 0.2,
                    delay: shouldShowActions ? 1.4 : 0,
                  }}
                >
                  <motion.div className="member__container-image">
                    <motion.img
                      src={Jhonas}
                      initial={{
                        opacity: 0.5,
                      }}
                      animate={{
                        opacity: index === 8 ? 1 : 0.5,
                      }}
                    ></motion.img>
                  </motion.div>
                </motion.div>
                <motion.div
                  className="member__container"
                  onClick={() => handleTeamClick(9)}
                  initial={{ rotateY: 40, opacity: 0 }}
                  animate={{
                    rotateY: shouldShowActions ? 0 : 40,
                    opacity: shouldShowActions ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: shouldShowActions ? 2 : 0.2,
                    delay: shouldShowActions ? 1.6 : 0,
                  }}
                >
                  <motion.div className="member__container-image">
                    <motion.img
                      src={Mike}
                      initial={{
                        opacity: 0.5,
                      }}
                      animate={{
                        opacity: index === 9 ? 1 : 0.5,
                      }}
                    ></motion.img>
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
            <motion.div
              className="about__row row-5"
              animate={{
                translateX: shouldShowActions ? 0 : -width,
              }}
              transition={{
                ease: [0.6, 0.01, -0.05, 0.95],
                duration: 1.8,
                delay: 0,
              }}
            >
              <motion.div
                className="member__container--row"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <motion.div
                  className="member__container"
                  onClick={() => handleTeamClick(10)}
                  initial={{ rotateY: 40, opacity: 0 }}
                  animate={{
                    rotateY: shouldShowActions ? 0 : 40,
                    opacity: shouldShowActions ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: shouldShowActions ? 2 : 0.2,
                    delay: shouldShowActions ? 1.8 : 0,
                  }}
                >
                  <motion.div className="member__container-image">
                    <motion.img
                      src={Gab}
                      initial={{
                        opacity: 0.5,
                      }}
                      animate={{
                        opacity: index === 10 ? 1 : 0.5,
                      }}
                    ></motion.img>
                  </motion.div>
                </motion.div>
                <motion.div
                  className="member__container"
                  onClick={() => handleTeamClick(11)}
                  initial={{ rotateY: 40, opacity: 0 }}
                  animate={{
                    rotateY: shouldShowActions ? 0 : 40,
                    opacity: shouldShowActions ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: shouldShowActions ? 2 : 0.2,
                    delay: shouldShowActions ? 2 : 0,
                  }}
                >
                  <motion.div className="member__container-image">
                    <motion.img
                      src={JC}
                      initial={{
                        opacity: 0.5,
                      }}
                      animate={{
                        opacity: index === 11 ? 1 : 0.5,
                      }}
                    ></motion.img>
                  </motion.div>
                </motion.div>
                <motion.div
                  className="member__container"
                  onClick={() => handleTeamClick(12)}
                  initial={{ rotateY: 40, opacity: 0 }}
                  animate={{
                    rotateY: shouldShowActions ? 0 : 40,
                    opacity: shouldShowActions ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: shouldShowActions ? 2 : 0.2,
                    delay: shouldShowActions ? 2.2 : 0,
                  }}
                >
                  <motion.div className="member__container-image">
                    <motion.img
                      src={Evan}
                      initial={{
                        opacity: 0.5,
                      }}
                      animate={{
                        opacity: index === 12 ? 1 : 0.5,
                      }}
                    ></motion.img>
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>

            <motion.div
              initial={{
                width: "0%",
                zIndex: 0,
                opacity: 0,
              }}
              animate={{
                width: !isCEOShown ? "80%" : "0%",
                zIndex: !isCEOShown ? 1 : 0,
                opacity: !isCEOShown ? 1 : 0,
              }}
              style={{
                height: "10%",
                width: "80%",
                position: "absolute",
                left: "0%",
                bottom: "0%",
                background: "var(--primary-gradient)",
              }}
              transition={{
                ease: [0.6, 0.01, -0.05, 0.95],
                duration: shouldShowActions ? 2 : 0.6,
                delay: shouldShowActions ? 2.4 : 0.6,
              }}
            >
              <motion.div
                className="selected__name--container"
                initial={{
                  marginLeft: "0%",
                  opacity: 0,
                }}
                animate={{
                  marginLeft: !isCEOShown ? "10%" : "0%",
                  opacity: !isCEOShown ? 1 : 0,
                }}
                style={{
                  width: "80%",
                  height: "50%",
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: !isCEOShown ? 2 : 0.3,
                  delay: !isCEOShown ? 3 : 0.3,
                }}
              >
                {name}
              </motion.div>
              <motion.div
                initial={{
                  marginLeft: "0%",
                  opacity: 0,
                }}
                animate={{
                  marginLeft: !isCEOShown ? "10%" : "0%",
                  opacity: !isCEOShown ? 1 : 0,
                }}
                className="selected__title--container"
                style={{
                  width: "80%",
                  height: "50%",
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: !isCEOShown ? 2 : 0.3,
                  delay: !isCEOShown ? 3 : 0.3,
                }}
              >
                {title}
              </motion.div>
            </motion.div>

            <motion.div
              initial={{
                opacity: 0.5,
              }}
              animate={{
                opacity: isCEOShown ? 1 : 0.5,
              }}
              className={`${handleClick ? "" : "active"} about-btn`}
              onClick={handleClick}
              id="btnOurCEO"
            >
              Our CEO
            </motion.div>
            <motion.div
              initial={{
                opacity: 0.5,
              }}
              animate={{
                opacity: !isCEOShown ? 1 : 0.5,
              }}
              className={`${handleClick ? "" : "active"} about-btn`}
              onClick={handleClick}
              id="btnOurTeam"
            >
              Our Team
            </motion.div>
          </>
        )}
      </motion.div>
    </Suspense>
  );
}

export default About;
