import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { Route } from "react-router-dom";

import BDO from "../../assets/images/clients/bdo-life.png";
import Flexicare from "../../assets/images/clients/flexicare.png";
import FortuneLife from "../../assets/images/clients/fortune-life.png";
import Generali from "../../assets/images/clients/generali.png";
import InLife from "../../assets/images/clients/inlife.png";
import Medgate from "../../assets/images/clients/medgate.png";
import Paramount from "../../assets/images/clients/paramount.png";
import Pioneer from "../../assets/images/clients/pioneer.png";
import Getwell from "../../assets/images/clients/getwell.png";

import Actuarial from "../../assets/images/clients/actuarial-min.png";
import Cosmopolitan from "../../assets/images/clients/cosmopolitan-funeral-min.png";
import FederalLand from "../../assets/images/clients/federal-land-min.png";
import HealthFirst from "../../assets/images/clients/health-first-min.png";
import Infocycle from "../../assets/images/clients/infocycle-min.png";
import MLQU from "../../assets/images/clients/mlqu-min.png";
import MMC from "../../assets/images/clients/mmc-min.png";
import PCC from "../../assets/images/clients/pcc-min.png";
import Philexport from "../../assets/images/clients/philexport-min.png";
import UOM from "../../assets/images/clients/university-of-manila-min.png";

import "./Clients.scss";

function Clients() {
  const height = window.innerHeight;
  const width = window.innerWidth;
  const [offsetY, setOffsetY] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [value, setValue] = useState();

  const handleScroll = () => {
    setOffsetY(document.getElementsByClassName("App")[0].scrollTop);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 960px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [offsetY]);

  const refresh = () => {
    setValue({});
  };

  setTimeout(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      refresh();
    } else {
      // do nothing.
    }
  }, 1000);

  const ClientsMarq = ({ isTabletOrMobile }) => {
    return (
      <Marquee
        speed={isTabletOrMobile ? 0 : 100}
        pauseOnHover={true}
        play={true}
        direction={"right"}
        style={{
          height: "100%",
        }}
      >
        <a
          className='image__container--desktop'
          href='https://medgatephilippines.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={Medgate} alt='medgate' a></img>
        </a>
        <a
          href='http://actuary.org.ph/'
          target='_blank'
          rel='noopener noreferrer'
          className='image__container--desktop'
        >
          <img src={Actuarial} alt='actuarial'></img>
        </a>
        <a
          href='http://www.cosmopolitanfuneral.com.ph/'
          target='_blank'
          rel='noopener noreferrer'
          className='image__container--desktop'
        >
          <img src={Cosmopolitan} alt='cosmopolitan'></img>
        </a>
        <a
          href='https://federalland.ph/'
          target='_blank'
          rel='noopener noreferrer'
          className='image__container--desktop'
        >
          <img src={FederalLand} alt='federalland'></img>
        </a>
        <a
          href='https://www.healthfirst.com.ph/'
          target='_blank'
          rel='noopener noreferrer'
          className='image__container--desktop'
        >
          <img src={HealthFirst} alt='health-first'></img>
        </a>
        <a
          href='http://www.infocyclesolutions.com/'
          target='_blank'
          rel='noopener noreferrer'
          className='image__container--desktop'
        >
          <img src={Infocycle} alt='infocycle'></img>
        </a>
        <a
          href='https://www.mlqu.ph/'
          target='_blank'
          rel='noopener noreferrer'
          className='image__container--desktop'
        >
          <img src={MLQU} alt='mlqu'></img>
        </a>
        <a
          href='https://www.mmc.com.ph/'
          target='_blank'
          rel='noopener noreferrer'
          className='image__container--desktop'
        >
          <img src={MMC} alt='mmc'></img>
        </a>
        <a
          href='http://www.philcancer.org.ph/'
          target='_blank'
          rel='noopener noreferrer'
          className='image__container--desktop'
        >
          <img src={PCC} alt='pcc'></img>
        </a>
        <a
          href='https://philexport.ph/'
          target='_blank'
          rel='noopener noreferrer'
          className='image__container--desktop'
        >
          <img src={Philexport} alt='philexport'></img>
        </a>
        <a
          href='http://www.um.edu.ph/'
          target='_blank'
          rel='noopener noreferrer'
          className='image__container--desktop'
        >
          <img src={UOM} alt='uom'></img>
        </a>
      </Marquee>
    );
  };

  return (
    <motion.div
      className='clients'
      style={{
        height: isTabletOrMobile ? "100%" : height - 50,
        width: width,
        paddingLeft: isTabletOrMobile ? "10%" : width * 0.02,
        paddingRight: isTabletOrMobile ? "10%" : width * 0.02,
      }}
    >
      {isDesktopOrLaptop && (
        <>
          <motion.div
            className='clients__row main-head'
            style={{ width: "80% !important" }}
          >
            We are pioneering a Group Medical and HMO Software Solution to the
            following Life Insurance and HMO Companies:
          </motion.div>
          <motion.div
            className='clients__row row-1'
            style={{
              transform: `translateY(-${offsetY * 0.1}px)`,
            }}
          >
            <a
              href='https://www.bdo.com.ph/bdolife/home'
              target='_blank'
              rel='noopener noreferrer'
              className='image__container--desktop'
            >
              <img src={BDO} alt='bdo-life'></img>
            </a>
            <a
              href='https://www.generali.com.ph/'
              target='_blank'
              rel='noopener noreferrer'
              className='image__container--desktop'
            >
              <img src={Generali} alt='generali'></img>
            </a>
            <a
              href='https://www.insularlife.com.ph/'
              target='_blank'
              rel='noopener noreferrer'
              className='image__container--desktop'
            >
              <img src={InLife} alt='inlife'></img>
            </a>
            <a
              href='http://website.fortunelife.com/'
              target='_blank'
              rel='noopener noreferrer'
              className='image__container--desktop'
            >
              <img src={FortuneLife} alt='fortune-life'></img>
            </a>
          </motion.div>
          <motion.div
            className='clients__row row-2'
            style={{
              transform: `translateY(-${offsetY * 0.1}px)`,
            }}
          >
            <a
              href='https://www.paramount.com.ph/'
              target='_blank'
              rel='noopener noreferrer'
              className='image__container--desktop'
            >
              <img src={Paramount} alt='paramount'></img>
            </a>
            <a
              href='https://pioneer.com.ph/'
              target='_blank'
              rel='noopener noreferrer'
              className='image__container--desktop'
            >
              <img src={Pioneer} alt='pioneer'></img>
            </a>
            <a
              href='https://www.getwellhealthsystemsinc.com.ph/home/'
              target='_blank'
              rel='noopener noreferrer'
              className='image__container--desktop'
            >
              <img src={Getwell} alt='getwell'></img>
            </a>
            <a
              href='https://www.flexicare.com.ph/'
              target='_blank'
              rel='noopener noreferrer'
              className='image__container--desktop'
            >
              <img src={Flexicare} alt='flexicare'></img>
            </a>
          </motion.div>
          <motion.div
            className='clients__row row-desc'
            style={{
              textAlign: "center",
            }}
          >
            As we progress in evolving our expertise on the Software Development
            and Information Technology industry, we were able to execute 100%
            custom made tools that these companies require on their
            mission-critical application requirements.
          </motion.div>
          <ClientsMarq />
        </>
      )}
      {isTabletOrMobile && (
        <>
          <motion.div
            className='clients__row'
            style={{
              fontSize: "2em",
              textTransform: "none",
              letterSpacing: "normal",
              fontWeight: "normal",
              color: "var(--d-grey)",
              display: "inline-block",
              height: "20%",
              paddingTop: "10%",
              paddingBottom: "10%",
            }}
          >
            We are pioneering a Group Medical and HMO Software Solution to the
            following Life Insurance and HMO Companies:
          </motion.div>
          <motion.div
            className='clients__row--mobile'
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              overflow: "hidden",
              width: "100%",
              height: "80%",
              padding: "5% 0%",
            }}
          >
            <motion.div
              className='clients__row row-1'
              style={{
                display: "flex",
                flexDirection: "column",
                transform: `translateY(${offsetY * 0.08}px)`,
                justifyContent: "center",
                width: "50%",
                height: "100%",
                margin: "1%",
              }}
            >
              <motion.div className='image__container--mobile'>
                <img src={BDO} alt='bdo-life'></img>
              </motion.div>
              <motion.div className='image__container--mobile' style={{}}>
                <img src={Generali} alt='generali'></img>
              </motion.div>
              <motion.div className='image__container--mobile'>
                <img src={InLife} alt='insular-life'></img>
              </motion.div>
              <motion.div className='image__container--mobile'>
                <img src={FortuneLife} alt='fortune-life'></img>
              </motion.div>
            </motion.div>
            <motion.div
              className='clients__row row-2'
              style={{
                display: "flex",
                flexDirection: "column",
                transform: `translateY(-${offsetY * 0.08}px)`,
                justifyContent: "center",
                width: "50%",
                height: "100%",
                margin: "1%",
              }}
            >
              <motion.div className='image__container--mobile'>
                <img src={Paramount} alt='bdo-life'></img>
              </motion.div>
              <motion.div className='image__container--mobile'>
                <img src={Pioneer} alt='bdo-life'></img>
              </motion.div>
              <motion.div className='image__container--mobile'>
                <img src={Flexicare} alt='bdo-life'></img>
              </motion.div>
              <motion.div className='image__container--mobile'>
                <img src={Medgate} alt='bdo-life'></img>
              </motion.div>
            </motion.div>
          </motion.div>
          <div class='secondary__txt'>
            As we progress in evolving our expertise on the Software Development
            and Information Technology industry, we were able to execute 100%
            custom made tools that these companies require on their
            mission-critical application requirements.
          </div>
          <div
            className='marquee__container--mobile'
            style={{
              height: "50%",
              margin: "10% 0%",
            }}
          >
            <ClientsMarq />
          </div>
        </>
      )}
    </motion.div>
  );
}

export default Clients;
