import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { useMediaQuery } from "react-responsive";

import "./Banner.scss";

import Banner_2 from "../../assets/images/bnr-2.png";

import BackdropImage from "../../assets/images/img_nav-bg.png";

const height = window.innerHeight;
const width = window.innerWidth;

const bgAni_1 = {
  initial: {
    translateX: "100%",
    translateY: "-50%",
    opacity: 0,
    zIndex: -1,
  },
  animate: {
    translateX: "60%",
    translateY: "-50%",
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 3,
      delay: 3,
      zIndex: -1,
    },
  },
};

const bgAni_2 = {
  initial: {
    translateY: "0%",
    translateX: "-75%",
    opacity: 0,
  },
  animate: {
    translateY: "0%",
    translateX: "-40%",
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 2,
      delay: 2.8,
    },
  },
};

const bgAni_4 = {
  initial: {
    translateY: "100%",
    translateX: "-50%",
    opacity: 0,
  },
  animate: {
    translateY: "-50%",
    translateX: "-50%",
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 5,
      delay: 0.5,
    },
  },
};

const bgAni_5 = {
  initial: {
    translateY: "-50%",
    translateX: "-50%",
    opacity: 1,
  },
  animate: {
    translateY: "-175%",
    translateX: "-50%",
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 2,
      delay: 0.5,
    },
  },
};

const bgAni_6 = {
  initial: {
    translateY: "-50%",
    translateX: "-50%",
    opacity: 1,
  },
  animate: {
    translateY: "-175%",
    translateX: "-50%",
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 2,
      delay: 0.8,
    },
  },
};

const Banner = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 960px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  return (
    <motion.div
      className='banner'
      style={{
        marginTop: isTabletOrMobile ? 50 : 50,
        height: isTabletOrMobile ? height - 50 : height - 50,
        backgroundColor: isTabletOrMobile ? "#fff" : "var(--black)",
        justifyContent: isTabletOrMobile ? "flex-start" : "center",
        position: "relative",
        width: "100%",
      }}
    >
      <BackgroundAnimated />

      {isTabletOrMobile && (
        <>
          <img
            src={Banner_2}
            alt='backdrop-image'
            className='backdrop-image'
          ></img>
        </>
      )}

      {isDesktopOrLaptop && (
        <>
          <motion.img
            src={BackdropImage}
            alt='banner-image'
            className='backdrop-image'
            variants={bgAni_1}
            initial='initial'
            animate='animate'
          ></motion.img>
          <motion.img
            src={Banner_2}
            alt='banner-image'
            className='banner-2'
            variants={bgAni_2}
            initial='initial'
            animate='animate'
            style={{
              position: "absolute",
              opacity: 1,
              zIndex: -3,
              scale: 0.7,
            }}
          ></motion.img>
        </>
      )}

      <div className={"message__container"}>
        <motion.div className='h1__container'>
          <motion.div
            className='banner__top--container'
            style={{
              margin: "20px 0px",
            }}
          >
            <BannerRowTop
              title={"Introducing our Digital Insurtech Solutions"}
            />
          </motion.div>
          {/* <BannerRowBottom
            title={""}
          /> */}
          <BannerRowBottom title={"for Insurance and Healthcare Industry"} />
          <BannerMessage
            isDesktopOrLaptop={isDesktopOrLaptop}
            isTabletOrMobile={isTabletOrMobile}
            title={
              "Software Innovations designed for Insurance, HMO, and Healthcare Industry model."
            }
          />
          <div
            style={{
              color: "var(--white)",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <a href='https://toplogic.com.ph/hms' target='_blank'>
              <div
                className='button_link'
                style={{
                  height: "50px",
                  borderRadius: 10,
                  border: "1px solid var(--white)",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                View Website and Learn More
              </div>
            </a>
            <div
              className='button_link'
              style={{
                height: "50px",
                borderRadius: 10,
                border: "1px solid var(--white)",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.scrollTo({
                  top: document.getElementsByClassName("App")[0].offsetHeight,
                  behavior: "smooth",
                });
              }}
            >
              Contact us for live demo
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

const BackgroundAnimated = ({ disabled }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 960px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });
  return (
    <>
      <motion.div
        className='bg__initial'
        initial='initial'
        animate='animate'
        style={{
          background: "var(--primary-gradient)",
          height: "calc(100% + 200px)",
          width: "100%",
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 2,
        }}
        variants={disabled ? null : bgAni_5}
      ></motion.div>
      <motion.div
        className='bg__initial'
        initial='initial'
        animate='animate'
        style={{
          background: "var(--secondary-gradient)",
          height: "calc(100% + 200px)",
          width: "100%",
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 1,
        }}
        variants={disabled ? null : bgAni_6}
      ></motion.div>
    </>
  );
};

const GetStarted = ({ isTabletOrMobile }) => {
  return (
    <Link
      spy={true}
      to='contact'
      smooth={true}
      style={{
        display: isTabletOrMobile ? "flex" : "none",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
        background: "var(--primary-gradient)",
        cursor: "pointer",
        height: "5%",
        borderRadius: "5px",
      }}
      duration={1000}
    >
      Get Started
    </Link>
  );
};

const AnimatedLetters = ({ title }) => (
  <motion.span className='row-title' initial='initial' animate='animate'>
    {[...title].map((letter) => (
      <motion.span className={`row-letter ${letter == " " ? "space" : ""}`}>
        {letter}
      </motion.span>
    ))}
  </motion.span>
);

const BannerRowTop = ({ title }) => {
  return (
    <div className={"banner-row top"}>
      <div className='row-col'>
        <AnimatedLetters title={title} />
      </div>
    </div>
  );
};

const BannerRowCenter = ({ title, isTabletOrMobile }) => {
  return (
    <div className={"banner-row center"}>
      <AnimatedLetters title={title} isTabletOrMobile={isTabletOrMobile} />
    </div>
  );
};

const BannerRowBottom = ({ title }) => {
  return (
    <div className={"banner-row bottom"}>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          ease: [0.6, 0.01, -0.05, 0.95],
          duration: 0.8,
          delay: 0.3,
        }}
      ></motion.div>
      <AnimatedLetters title={title} />
    </div>
  );
};

const BannerMessage = ({ isDesktopOrLaptop, isTabletOrMobile }) => {
  return (
    <>
      {isDesktopOrLaptop && (
        <div
          className={"banner-row message"}
          style={{
            position: "relative",
            width: "70%",
            margin: "2.5% 0%",
            color: "var(--white)",
          }}
        >
          <motion.div
            initial={{
              marginTop: 100,
              opacity: 0,
            }}
            animate={{
              marginTop: 0,
              opacity: 1,
            }}
            transition={{
              ease: [0.6, 0.01, -0.05, 0.95],
              duration: 2,
              delay: 2,
            }}
          >
            Software Innovations and Solutions designed for Insurance, HMO, and
            Healthcare Industry model.
          </motion.div>
        </div>
      )}

      {isTabletOrMobile && (
        <div className={"banner-row message"}>
          <motion.div
            initial={{
              marginTop: 100,
              opacity: 0,
            }}
            animate={{
              marginTop: 0,
              opacity: 1,
            }}
            transition={{
              ease: [0.6, 0.01, -0.05, 0.95],
              duration: 2,
              delay: 3,
            }}
          >
            Software Innovations and Solutions designed for Insurance, HMO, and
            Healthcare Industry model.
          </motion.div>
        </div>
      )}
    </>
  );
};

export default Banner;
