import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { IconContext } from "react-icons/lib";
import { useMediaQuery } from "react-responsive";

import { FaBars, FaTimes } from "react-icons/fa";
import { Button } from "./Button";

import WhiteLogo from "../../assets/images/logotype_w.png";
import Logo from "../../assets/images/logo.png";

import "./Navbar.scss";

function Navbar() {
  const height = window.innerHeight;
  const width = window.innerWidth;
  const [click, setClick] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 960px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  const handleClick = () => {
    if (width < 959.9) {
      setClick(!click);
    } else {
      setClick(false);
    }
  };
  const closeMobileMenu = () => setClick(false);

  const scrollOffset = height * 0.1;

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        {isTabletOrMobile && (
          <div
            className='navbar mobile'
            style={{
              zIndex: 11,
            }}
          >
            <div className='navbar-container container'>
              <Link
                spy={true}
                to='banner'
                className='navbar-logo'
                onClick={closeMobileMenu}
                smooth={true}
                duration={1000}
                offset={-50}
                style={{
                  cursor: "pointer",
                }}
              >
                <img src={WhiteLogo} alt='toplogic-logo' id='tccLogo' />
              </Link>
              <div className='menu-icon' onClick={handleClick}>
                {click ? <FaTimes /> : <FaBars />}
              </div>
              <motion.ul
                className='nav-menu'
                initial={{
                  opacity: click ? 1 : 0,
                  zIndex: click ? 10 : -10,
                  translateX: click ? "-50%" : "-150%",
                }}
                animate={{
                  opacity: click ? 1 : 0,
                  zIndex: click ? 10 : -10,
                  translateX: click ? "-50%" : "-150%",
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: 0.2,
                  delay: !click ? 0 : 0,
                }}
              >
                <motion.li
                  className='nav-item'
                  initial={{
                    rotateX: "0deg",
                    opacity: 0,
                  }}
                  animate={{
                    rotateX: click ? "0deg" : "90deg",
                    opacity: click ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                    delay: 0.2,
                  }}
                >
                  <Link
                    spy={true}
                    to='banner'
                    className='nav-links'
                    activeClass='active'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                    offset={-100}
                  >
                    Home
                  </Link>
                </motion.li>
                <motion.li
                  className='nav-item'
                  initial={{
                    rotateX: "0deg",
                    opacity: 0,
                  }}
                  animate={{
                    rotateX: click ? "0deg" : "90deg",
                    opacity: click ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                    delay: 0.2,
                  }}
                >
                  <Link
                    spy={true}
                    to='clients'
                    className='nav-links'
                    activeClass='active'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                    offset={-100}
                  >
                    Clients
                  </Link>
                </motion.li>
                <motion.li
                  className='nav-item'
                  initial={{
                    rotateX: "0deg",
                    opacity: 0,
                  }}
                  animate={{
                    rotateX: click ? "0deg" : "90deg",
                    opacity: click ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                    delay: 0.4,
                  }}
                >
                  <Link
                    spy={true}
                    to='about'
                    className='nav-links'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                    offset={-100}
                  >
                    About Us
                  </Link>
                </motion.li>

                <motion.li
                  className='nav-item'
                  initial={{
                    rotateX: "0deg",
                    opacity: 0,
                  }}
                  animate={{
                    rotateX: click ? "0deg" : "90deg",
                    opacity: click ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                    delay: 0.4,
                  }}
                >
                  <a
                    className='nav-links'
                    href='https://toplogic.com.ph/hms'
                    target={"_blank"}
                    onClick={closeMobileMenu}
                  >
                    HMS
                  </a>
                </motion.li>
                <motion.li
                  className='nav-item'
                  initial={{
                    rotateX: "0deg",
                    opacity: 0,
                  }}
                  animate={{
                    rotateX: click ? "0deg" : "90deg",
                    opacity: click ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                    delay: 0.6,
                  }}
                >
                  <Link
                    spy={true}
                    to='services'
                    className='nav-links'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                    offset={-100}
                  >
                    Services
                  </Link>
                </motion.li>
                {/* <motion.li
                  className='nav-item'
                  initial={{
                    rotateX: "0deg",
                    opacity: 0,
                  }}
                  animate={{
                    rotateX: click ? "0deg" : "90deg",
                    opacity: click ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                    delay: 0.8,
                  }}
                >
                  <Link
                    spy={true}
                    to='mas'
                    className='nav-links'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                    offset={-scrollOffset / 2}
                  >
                    MAS
                  </Link>
                </motion.li> */}
                <motion.li
                  className='nav-item'
                  initial={{
                    rotateX: "0deg",
                    opacity: 0,
                  }}
                  animate={{
                    rotateX: click ? "0deg" : "90deg",
                    opacity: click ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                    delay: 1,
                  }}
                >
                  <Link
                    spy={true}
                    to='careers'
                    className='nav-links'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                    offset={-scrollOffset / 2}
                  >
                    Careers
                  </Link>
                </motion.li>
                <motion.li
                  className='nav-item'
                  initial={{
                    rotateX: "0deg",
                    opacity: 0,
                  }}
                  animate={{
                    rotateX: click ? "0deg" : "90deg",
                    opacity: click ? 1 : 0,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.6,
                    delay: 1.2,
                  }}
                >
                  <Link
                    spy={true}
                    to='contact'
                    className='nav-links'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                  >
                    Contact Us
                  </Link>
                </motion.li>
                <motion.li
                  className='nav-item'
                  style={{
                    height: "10%",
                    fontSize: "2.5vw !important",
                    fontWeight: "0",
                  }}
                >
                  <div className='footer'>
                    TopLogic Consulting Corporation © 2020
                  </div>
                </motion.li>
              </motion.ul>
            </div>
          </div>
        )}
        {isDesktopOrLaptop && (
          <div className='navbar desktop'>
            <div className='navbar-container container'>
              <Link
                to='banner'
                className='navbar-logo'
                onClick={closeMobileMenu}
                smooth={true}
                duration={1000}
                offset={-100}
                style={{
                  cursor: "pointer",
                }}
              >
                <img src={Logo} alt='toplogic-logo' id='tccLogo' />
              </Link>
              <ul className='nav-menu'>
                <li className='nav-item'>
                  <Link
                    to='banner'
                    className='nav-links'
                    activeClass='nav-links active'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                    offset={-100}
                  >
                    Home
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='clients'
                    className='nav-links'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                    offset={-50}
                  >
                    Clients
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='about'
                    className='nav-links'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                    offset={-75}
                  >
                    About Us
                  </Link>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-links'
                    href='https://toplogic.com.ph/hms'
                    target={"_blank"}
                    onClick={closeMobileMenu}
                  >
                    HMS
                  </a>
                </li>
                <li className='nav-item'>
                  <Link
                    to='services'
                    className='nav-links'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                    offset={-75}
                  >
                    Services
                  </Link>
                </li>
                {/* <li className='nav-item'>
                  <Link
                    to='mas'
                    className='nav-links'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                    offset={-50}
                  >
                    MAS
                  </Link>
                </li> */}
                <li className='nav-item'>
                  <Link
                    to='careers'
                    className='nav-links'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                    offset={-50}
                  >
                    Careers
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='contact'
                    className='nav-links'
                    onClick={closeMobileMenu}
                    smooth={true}
                    duration={1000}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
