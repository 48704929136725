import Logotype from "../../assets/images/logotype_w.png";

import "./Footer.scss";

function Footer() {
  return (
    <div className='mobile__footer'>
      <img src={Logotype}></img>
      <span>© 2008</span>
    </div>
  );
}

export default Footer;
