import React, { useState, useRef, useEffect, lazy, Suspense } from "react";
import { css } from "@emotion/react";
import "./App.scss";
import { motion } from "framer-motion";

import ScaleLoader from "react-spinners/ScaleLoader";

import Navbar from "./components/pages/Navbar";
import Banner from "./components/pages/Banner";
import Clients from "./components/pages/Clients";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Services from "./components/pages/Services";
import Careers from "./components/pages/Careers";
// import MAS from "./components/pages/MAS";
// import Cursor from "./components/Cursor";
import Footer from "./components/pages/Footer";
import MapsModal from "./components/MapsModal";
// import Chatbot from "./components/Chatbot";

function App() {
  // Lazy

  // const Navbar = lazy(() => import("./components/pages/Navbar"));
  // // const Banner = lazy(() => import("./components/pages/BannerSlide"));
  // const Banner = lazy(() => import("./components/pages/Banner"));
  // const Clients = lazy(() => import("./components/pages/Clients"));
  // const About = lazy(() => import("./components/pages/About"));
  // const Contact = lazy(() => import("./components/pages/Contact"));
  // const Services = lazy(() => import("./components/pages/Services"));
  // const Careers = lazy(() => import("./components/pages/Careers"));
  // const MAS = lazy(() => import("./components/pages/MAS"));
  // const Footer = lazy(() => import("./components/pages/Footer"));
  // const BannerSlide = lazy(() => import("./components/pages/BannerSlide"));

  // Suspense

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  const renderLoader = () => (
    <motion.p
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "var(--white)",
        fontSize: "10vw",
        fontWeight: "800",
        height: "100%",
        width: "100%",
        background: "var(--primary-gradient)",
      }}
    >
      <ScaleLoader color={"#fff"} css={override} size={50} />
    </motion.p>
  );

  const [height, setHeight] = useState(window.height);
  const [width, setWidth] = useState(window.width);
  const [isMapsActive, setIsMapsActive] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setHeight(e.currentTarget.innerHeight);
      setWidth(e.currentTarget.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", (e) => {
        setHeight(e.currentTarget.innerHeight);
        setWidth(e.currentTarget.innerWidth);
      });
    };
  });

  return (
    <Suspense fallback={renderLoader()}>
      <motion.div className='App'>
        <Navbar />
        <MapsModal
          isMapsActive={isMapsActive}
          setIsMapsActive={setIsMapsActive}
        />
        {/* <Chatbot /> */}
        {/* <BannerSlide name='slider' /> */}
        <Banner name='banner' />
        <Clients name='clients' />
        <About name='about' />
        <Services name='services' />
        {/* <MAS name='mas' /> */}
        <Careers name='careers' />
        <Contact name='contact' setIsMapsActive={setIsMapsActive} />
        <Footer />
      </motion.div>
    </Suspense>
  );
}

export default App;
