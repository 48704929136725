import React from "react";
import "./Button.scss";

const STYLES = ["btn--primary", "btn--outline", "btn--gradient"];
const SIZES = ["btn--medium", "btn--large", "btn--mobile", "btn--wide"];
const COLOR = ["primary", "black", "grey", "white"];
const FONT_COLOR = ["primary", "black", "grey", "white"];
const IS_VISIBLE = [true, false];
const WIDTH = ["10%", "50%", "100%"];

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonColor,
  fontColor,
  fontSize,
  isVisible,
  buttonWidth,
}) => {
  const checkIfVisible = IS_VISIBLE.includes(isVisible)
    ? isVisible
    : IS_VISIBLE[true];
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  const checkButtonColor = COLOR.includes(buttonColor) ? buttonColor : COLOR[0];

  const checkFontColor = FONT_COLOR.includes(fontColor)
    ? fontColor
    : FONT_COLOR[0];

  const checkButtonWidth = WIDTH.includes(buttonWidth) ? buttonWidth : WIDTH[0];

  return (
    <button
      className={`btn ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor} ${checkIfVisible}`}
      onClick={onClick}
      tyle={type}
      style={{
        display: isVisible ? "inline-block" : "none",
        color: checkFontColor,
        width: checkButtonWidth,
        fontSize: "1.8vh",
      }}
    >
      {children}
    </button>
  );
};
