import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

import "react-toastify/dist/ReactToastify.css";
import "./Contact.scss";
import { Button } from "./Button";

toast.configure();
const notify = () => {
  toast("Basic notification!");
};

function Contact({ setIsMapsActive }) {
  const height = window.innerHeight;
  const width = window.innerWidth;

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 960px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  function sendEmail(e) {
    e.preventDefault();
    const arrFields = [];

    if (document.getElementById("txtHp").value == "") {
      if (document.getElementById("txtName").value == "") {
        arrFields.push("name");
      }

      if (document.getElementById("txtCompName").value == "") {
        arrFields.push("company name");
      }

      if (document.getElementById("txtPhone").value == "") {
        arrFields.push("phone number");
      }

      if (document.getElementById("txtEmail").value == "") {
        arrFields.push("e-mail address");
      }

      if (document.getElementById("txtMessage").value == "") {
        arrFields.push("message");
      }

      console.log(arrFields);

      if (arrFields.length > 0) {
        toast.warning(`Please fill out all fields.`, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.success("Success! Your message has been sent.", {
          position: toast.POSITION.TOP_CENTER,
        });
        emailjs
          .sendForm(
            "service_nrom6sm",
            "template_6qw8zis",
            e.target,
            "user_CRUbPL4RXgAq0YDDqftS1"
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
        e.target.reset();
      }
    } else {
      return;
    }
  }

  return (
    <>
      {isDesktopOrLaptop && (
        <div
          className='contact'
          style={{ height: height / 1.6, position: "relative" }}
        >
          <div className='contact__col'>
            <div className='contact__row-header'>
              Got questions? We'd love to hear them from you!
            </div>
            <div className='contact__row-header'>
              Email us at{" "}
              <span style={{ color: "var(--primary)" }}>consulting</span>
              @toplogic.com.ph
            </div>
            <div className='contact__row-details'>
              <div className='details__col col-1'>
                <ul className='address'>
                  <li className='address-line'>
                    9th Floor, One Griffinstone Building, Commerce Avenue,
                    corner Spectrum Midway, Filinvest Corporate City, Alabang,
                    Muntinlupa
                  </li>
                  <li
                    className='address-line link'
                    onClick={() => {
                      setIsMapsActive(true);
                    }}
                  >
                    See in Google Maps
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className='contact__col'
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <form id='contactForm' method='POST' onSubmit={sendEmail}>
              <input
                id='txtHp'
                style={{
                  display: "none",
                  pointerEvents: "none",
                  cursor: "none",
                }}
              ></input>
              <input
                className='toplogic-input'
                placeholder='Name'
                id='txtName'
                name='name'
              />
              <input
                className='toplogic-input'
                placeholder='Company Name'
                id='txtCompName'
                name='companyname'
              />
              <input
                className='toplogic-input inputmask-num'
                placeholder='Phone'
                id='txtPhone'
                name='phone'
              />
              <input
                className='toplogic-input'
                placeholder='E-mail Address'
                id='txtEmail'
                name='email'
              />
              <textarea
                className='toplogic-input full-width thin-scroll'
                placeholder='Message'
                id='txtMessage'
                name='message'
              ></textarea>
              <Button
                id='btnSubmit'
                children='Submit'
                fontColor='white'
                className='btn--gradient btn--mobile btn--wide'
                buttonWidth='100%'
                isVisible={isDesktopOrLaptop}
              />
            </form>
          </div>
        </div>
      )}

      {isTabletOrMobile && (
        <div
          className='contact'
          style={{ height: "90%", position: "relative" }}
        >
          <div className='contact__col'>
            <div className='contact__row-header'>
              Got questions? We'd love to hear them from you!
            </div>
            <div className='contact__row-desc'>
              Please fill out the form below to stay tuned.
            </div>
          </div>
          <div className='contact__col'>
            <form id='contactForm' method='POST' onSubmit={sendEmail}>
              <input
                id='txtHp'
                style={{
                  display: "none",
                  pointerEvents: "none",
                  cursor: "none",
                }}
              ></input>
              <input
                className='toplogic-input'
                placeholder='Name'
                id='txtName'
                name='name'
              />
              <input
                className='toplogic-input'
                placeholder='Company Name'
                id='txtCompName'
                name='companyname'
              />
              <input
                className='toplogic-input inputmask-num'
                placeholder='Phone'
                id='txtPhone'
                name='phone'
              />
              <input
                className='toplogic-input'
                placeholder='E-mail Address'
                id='txtEmail'
                name='email'
              />
              <textarea
                className='toplogic-input full-width thin-scroll'
                placeholder='Message'
                id='txtMessage'
                name='message'
              ></textarea>
              <Button
                id='btnSubmit'
                children='Submit'
                fontColor='white'
                className='btn--gradient btn--mobile btn--wide'
                buttonWidth='100%'
                isVisible={isTabletOrMobile}
              />
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Contact;
