import React, { useState, useEffect, Component } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FaTimes } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

import "./Services.scss";

import DataAnalytics from "../../assets/images/services/Data-Analytics.png";
import FinancialDataServices from "../../assets/images/services/Financial-Data-Services.png";
import DataWarehousing from "../../assets/images/services/Data-Warehousing.png";

import MyDrive from "../../assets/images/mobile-development/my-drive (1).png";
import InLife from "../../assets/images/mobile-development/inlife.png";
import FortuneLife from "../../assets/images/mobile-development/fortune-life.png";
import Generali from "../../assets/images/mobile-development/generali.png";
import Wellness_Kit from "../../assets/images/mobile-development/wellness-kit.png";
import My_Wheelbox from "../../assets/images/mobile-development/my-wheelbox.png";

import MyDrive_mobile from "../../assets/images/mobile-development/md-mobile.png";
import InLife_mobile from "../../assets/images/mobile-development/insular-mobile.png";
import FortuneLife_mobile from "../../assets/images/mobile-development/fl-mobile.png";
import Generali_mobile from "../../assets/images/mobile-development/generali-mobile.png";
import Wellness_Kit_mobile from "../../assets/images/mobile-development/wellness kit-mobile.png";
import My_Wheelbox_mobile from "../../assets/images/mobile-development/my-wheelbox-mobile.png";

import BgMyDrive from "../../assets/images/mobile-development/bg-1.png";
import BgInLife from "../../assets/images/mobile-development/bg-2.png";
import BgFortuneLife from "../../assets/images/mobile-development/bg-3.png";
import BgGenerali from "../../assets/images/mobile-development/bg-4.png";
import BgWellnessKit from "../../assets/images/mobile-development/bg-5.png";
import BgWheelbox from "../../assets/images/mobile-development/bg-6.png";

import LogoMyDrive from "../../assets/images/mobile-development/paramount-logo-white.png";
import LogoInLife from "../../assets/images/mobile-development/inlife-logo-white.png";
import LogoFortuneLife from "../../assets/images/mobile-development/fortune-life-white.png";
import LogoGenerali from "../../assets/images/mobile-development/generali-logo-white.png";
import LogoWellnessKit from "../../assets/images/mobile-development/logo-main-wht.png";
import LogoWheelbox from "../../assets/images/mobile-development/img_mywheelbox_logotype.png";

import IllusMember from "../../assets/images/web-development/member-portal.png";
import IllusSales from "../../assets/images/web-development/sales-portal.png";
import IllusCorporate from "../../assets/images/web-development/corporate-hr.png";

import BgMember from "../../assets/images/web-development/bg-mas.png";
import BgSales from "../../assets/images/web-development/bg-sp.png";
import BgCorporate from "../../assets/images/web-development/bg-hr.png";

import HR_1 from "../../assets/images/web-development/hr-1.png";
import HR_2 from "../../assets/images/web-development/hr-2.png";
import HR_3 from "../../assets/images/web-development/hr-3.png";
import MP_1 from "../../assets/images/web-development/mp-1.png";
import MP_2 from "../../assets/images/web-development/mp-2.png";
import MP_3 from "../../assets/images/web-development/mp-3.png";
import SP_1 from "../../assets/images/web-development/sp-1.png";
import SP_2 from "../../assets/images/web-development/sp-2.png";
import SP_3 from "../../assets/images/web-development/sp-3.png";

import Banner_1 from "../../assets/images/banner-1.png";
import Banner_2 from "../../assets/images/banner-2.png";

import BgWeb from "../../assets/images/bg--web.png";

function Services() {
  const height = window.innerHeight;
  const width = window.innerWidth;

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 961px)",
  });

  const [hover, setHover] = useState(true);
  const [click, setClick] = useState(false);

  const [count, setCount] = useState(0);
  const [parentCount, setParentCount] = useState(0);

  const [mobileCount, setMobileCount] = useState(0);
  const [background, setBackground] = useState("");
  const [mobileLogo, setMobileLogo] = useState("");
  const [isActive, setIsActive] = useState(false);

  const [webBackground, setWebBackground] = useState("");
  const [webIllus, setWebIllus] = useState("");
  const [isWebActive, setIsWebActive] = useState(false);

  const [activeData, setActiveData] = useState();

  const [activeWebSlideURL, setActiveWebSlideURL] = useState(MP_1);
  const [toggleAnim, setToggleAnim] = useState(true);

  const [isListSelected, setIsListSelected] = useState(false);

  const variants = {
    initial: {
      opacity: 0,
      translateX: isDesktopOrLaptop ? "-50%" : "",
      translateY: isDesktopOrLaptop ? "-80%" : "",
      scale: 0.2,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1.2,
        delay: 0,
      },
    },
    animate: {
      opacity: 1,
      scale: 1,
      translateX: isDesktopOrLaptop ? "-50%" : "",
      translateY: isDesktopOrLaptop ? "-80%" : "",
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 0.8,
        delay: 0,
      },
    },
  };

  const bgAni_1 = {
    initial: {
      translateY: "60%",
      translateX: "30%",
      opacity: 0,
      scale: ".4",
    },
    animate: {
      translateY: "30%",
      translateX: "60%",
      scale: ".4",
      opacity: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 2,
      },
    },
  };

  const bgAni_2 = {
    initial: {
      translateY: "90%",
      translateX: "0%",
      opacity: 0,
      scale: ".4",
    },
    animate: {
      translateY: "0%",
      translateX: "90%",
      opacity: 0.2,
      scale: ".4",
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 2,
      },
    },
  };

  useEffect(() => {
    switch (count) {
      case 1:
        setActiveWebSlideURL(MP_1);
        break;
      case 2:
        setActiveWebSlideURL(SP_1);
        break;
      default:
        setActiveWebSlideURL(HR_1);
        break;
    }
    setIsListSelected(false);
  }, [count]);

  const backroomData = [
    {
      id: 1,
      title: "Data Warehousing",
      desc: `An Enterprise-grade Data Warehouse, a system designed
          intristically to the requirement of BDO Life Assurance
          Company, Inc.`,
    },
    {
      id: 2,
      title: "Financial Data Services",
      desc: `Keeping track of financial information and reporting needs, a
          project designed for a Japanese engineering company here in
          the Philippines.`,
    },
    {
      id: 3,
      title: "Deep Data Analytics",
      desc: `A backroom tools designed for the Actuarial Society of the
          Philippines or ASP that enables them to produce the
          consolidated data needed by the Insurance Commission.`,
    },
  ];

  const mobileData = [
    {
      id: 1,
      title: "My Drive | Motor Insurance",
      desc: `My Drive offers a convenient way for Paramount Insured Members
      to request a quotation, be notified during policy renewal and
      for them to have a maintenance of their vehicle records.`,
      color: "var(--black)",
    },
    {
      id: 2,
      title: "HealthCare Member Mobile app for HMO and Insurance",
      desc: `HealthCare Member app is a pixel perfect design with the
      simplicity and sophisticated functionalities for the Benefit
      of the Members.`,
      color: "#03A8A4",
    },
    {
      id: 3,
      title: "Retail Life Insurance Selling for FortuneLife",
      desc: `A mobile app design for helping FortuneLife policy holders to
      have a real-time and seamless insurance application from
      registration all the way to policy issuance.`,
      color: "#003A80",
    },
    {
      id: 4,
      title: "Generali",
      desc: `As Generali Philippines puts their energy in providing the most well thought of mobile application in the Life Insurance Industry, TopLogic aims to join that goal by delivering highly sophisticated end to end features such as LOA Issuance, Reimbursement Claim, Wellness Program, etc.`,
      color: "#C5221B",
    },
    {
      id: 5,
      title: "Health Log Kit",
      desc: `Having access of your medical records and health journal via your smartphone device is one of the best way to keep track of your health history and helps you to be an informed patient. With this, knowledge about this important data allows you to take more control of your healthcare not just for you but including your whole family members.
      `,
      color: "#C5221B",
    },
    {
      id: 6,
      title: "My WheelBox",
      desc: `This is your Vehicle Digital Booklet which allows you to record your vehicle’s Insurance Policy, PMS records, and significant details of your vehicle’s track record. It also features a reminder that will help you keep your vehicle in Tip-top shape.`,
      color: "#C5221B",
    },
  ];

  const webData = [
    {
      id: 1,
      title: "Member Portal",
      desc: `A Web-based platform that allows the Member or Enrollees of an HMO or Life Insurance Group Medical products to have full access on their benefit coverage and allows them to enroll and manage their dependents’ benefits as well.`,
    },
    {
      id: 2,
      title: "Sales Portal",
      desc: `A sub-component web portal of our Medical Assistance Software (MAS) Suite, tailored-fit to any HMO’s and Life Insurance Sales component process that will empower their field represemntatives to close accounts from anywhere.`,
    },
    {
      id: 3,
      title: "Corporate HR",
      desc: `Streamline your Member Enrollment, Renewal, and Member Adjustment process online and live through the MAS Corporate HR Web Portal bringing direct connectivity between the HMO and their Corporate Accounts.`,
    },
  ];

  const InnerSlide = ({ disabled }) => {
    return (
      <>
        <motion.div
          class='inner__slide'
          style={{
            backgroundImage: `url(${BgWeb})`,
            backgroundSize: "cover",
          }}
        >
          <motion.div className='inner__slide--container'>
            <motion.div className='inner__slide--item'>
              <motion.img
                src={activeWebSlideURL}
                alt='main-web-slide'
                initial={"initial"}
                animate={"animate"}
                variants={variants}
                style={{
                  zIndex: 5,
                }}
              ></motion.img>
              <motion.img
                src={Banner_1}
                alt='banner-image'
                className='backdrop-image-1'
                variants={disabled ? null : bgAni_1}
                initial='initial'
                animate='animate'
                style={{
                  position: "absolute",
                  opacity: 1,
                  zIndex: 1,
                  top: "-10%",
                  left: "0%",
                }}
              ></motion.img>
              <motion.img
                src={Banner_2}
                alt='banner-image-2'
                className='backdrop-image'
                variants={disabled ? null : bgAni_2}
                initial='initial'
                animate='animate'
                style={{
                  position: "absolute",
                  opacity: 1,
                  zIndex: 1,
                  top: "-0%",
                  left: "-20%",
                }}
              ></motion.img>
            </motion.div>
            {/* <motion.div className='inner__slide--selections'>
                <MemberPortalSlide
                  className={`inner__slide--list ${
                    count === 1 ? "active" : ""
                  }`}
                />
                <HRPortalSlide
                  className={`inner__slide--list ${
                    count === 2 ? "active" : ""
                  }`}
                />
                <SalesPortalSlide
                  className={`inner__slide--list ${
                    count === 3 ? "active" : ""
                  }`}
                />
              </motion.div> */}
          </motion.div>
        </motion.div>
      </>
    );
  };

  const handleListSelected = (url) => {
    setIsListSelected(true);
  };

  class MemberPortalSlide extends Component {
    render() {
      return (
        <>
          <motion.div
            className={`inner__slide--list ${count === 1 ? "active" : ""}`}
          >
            <motion.img
              src={MP_1}
              alt='member-portal-1'
              onClick={() => {
                setActiveWebSlideURL(MP_1);
              }}
            ></motion.img>
            <motion.img
              src={MP_2}
              alt='member-portal-2'
              onClick={() => {
                setActiveWebSlideURL(MP_2);
              }}
            ></motion.img>
            <motion.img
              src={MP_3}
              alt='member-portal-3'
              onClick={() => {
                setActiveWebSlideURL(MP_3);
              }}
            ></motion.img>
          </motion.div>
        </>
      );
    }
  }

  const SalesPortalSlide = () => {
    return (
      <>
        <motion.div
          className={`inner__slide--list ${count === 2 ? "active" : ""}`}
        >
          <motion.img
            src={SP_1}
            alt='sales-portal-1'
            onClick={() => {
              setActiveWebSlideURL(SP_1);
            }}
          ></motion.img>
          <motion.img
            src={SP_2}
            alt='sales-portal-2'
            onClick={() => {
              setActiveWebSlideURL(SP_2);
            }}
          ></motion.img>
          <motion.img
            src={SP_3}
            alt='sales-portal-3'
            onClick={() => {
              setActiveWebSlideURL(SP_3);
            }}
          ></motion.img>
        </motion.div>
      </>
    );
  };

  const HRPortalSlide = () => {
    return (
      <>
        <motion.div
          className={`inner__slide--list ${count === 3 ? "active" : ""}`}
        >
          <motion.img
            src={HR_1}
            alt='hr-portal-1'
            onClick={() => {
              setActiveWebSlideURL(HR_1);
            }}
          ></motion.img>
          <motion.img
            src={HR_2}
            alt='hr-portal-2'
            onClick={() => {
              setActiveWebSlideURL(HR_2);
            }}
          ></motion.img>
          <motion.img
            src={HR_3}
            alt='hr-portal-3'
            onClick={() => {
              setActiveWebSlideURL(HR_3);
            }}
          ></motion.img>
        </motion.div>
      </>
    );
  };

  const ServiceIndicators = () => {
    if (parentCount !== 2) {
      return (
        <>
          {isDesktopOrLaptop && (
            <AnimatePresence>
              <motion.div
                className={`container__slide--ind ${
                  parentCount === 3 ? "web" : ""
                }`}
                initial={"collapsed"}
                animate={count == 1 ? "expanded" : "collapsed"}
                exit={"collapsed"}
              >
                <motion.div
                  className='slide--indicator'
                  variants={IndicatorVariants}
                  initial={"collapsed"}
                  animate={count == 1 ? "expanded" : "collapsed"}
                  exit={"collapsed"}
                ></motion.div>
                <motion.div
                  className='slide--indicator'
                  variants={IndicatorVariants}
                  initial={"collapsed"}
                  animate={count == 2 ? "expanded" : "collapsed"}
                  exit={"collapsed"}
                ></motion.div>
                <motion.div
                  className='slide--indicator'
                  variants={IndicatorVariants}
                  initial={"collapsed"}
                  animate={count == 3 ? "expanded" : "collapsed"}
                  exit={"collapsed"}
                ></motion.div>
              </motion.div>
            </AnimatePresence>
          )}
        </>
      );
    } else {
      return (
        <>
          {isDesktopOrLaptop && (
            <motion.div className='container__slide--ind'>
              <motion.div
                className='slide--indicator'
                variants={IndicatorVariants}
                initial={"collapsed"}
                animate={mobileCount == 1 ? "expanded" : "collapsed"}
                exit={"collapsed"}
              ></motion.div>
              <motion.div
                className='slide--indicator'
                variants={IndicatorVariants}
                initial={"collapsed"}
                animate={mobileCount == 2 ? "expanded" : "collapsed"}
                exit={"collapsed"}
              ></motion.div>
              <motion.div
                className='slide--indicator'
                variants={IndicatorVariants}
                initial={"collapsed"}
                animate={mobileCount == 3 ? "expanded" : "collapsed"}
                exit={"collapsed"}
              ></motion.div>
              <motion.div
                className='slide--indicator'
                variants={IndicatorVariants}
                initial={"collapsed"}
                animate={mobileCount == 4 ? "expanded" : "collapsed"}
                exit={"collapsed"}
              ></motion.div>
              <motion.div
                className='slide--indicator'
                variants={IndicatorVariants}
                initial={"collapsed"}
                animate={mobileCount == 5 ? "expanded" : "collapsed"}
                exit={"collapsed"}
              ></motion.div>
              <motion.div
                className='slide--indicator'
                variants={IndicatorVariants}
                initial={"collapsed"}
                animate={mobileCount == 6 ? "expanded" : "collapsed"}
                exit={"collapsed"}
              ></motion.div>
            </motion.div>
          )}
        </>
      );
    }
  };

  const ServicesDesktopNew = () => {
    return (
      <>
        <motion.div
          className={`services__mobile-container new ${
            parentCount === 2 ? "active" : ""
          }`}
          initial={{
            opacity: 0,
            zIndex: parentCount === 2 ? 0 : -2,
          }}
          animate={{
            opacity: parentCount === 2 ? 2 : 0,
            zIndex: parentCount === 2 ? 1 : -2,
          }}
        >
          <div className='_serv__text--cont'>
            <div className='_serv__text--inner'>
              <div className='_serv__text--logo'>
                <LogoDiv />
              </div>
              <ActiveContainer />
              <div className='_serv__text--nav'></div>
            </div>
          </div>
          <div className='_serv__img--index'>
            <motion.div
              className='services__container-hero'
              style={{
                background: "transparent !important",
              }}
              onClick={handleMobileCount__hero}
            >
              <motion.img
                src={MyDrive}
                alt='my-drive'
                initial={{
                  opacity: mobileCount === 1 ? 0 : 0,
                  translateY: mobileCount === 1 ? "-5%" : "0",
                  rotateY: -70,
                  scale: 0.8,
                  zIndex: 1,
                }}
                animate={{
                  opacity: mobileCount === 1 ? (isTabletOrMobile ? 1 : 1) : 0,
                  translateY: mobileCount === 1 ? "-5%" : "0",
                  rotateY: mobileCount === 1 ? 0 : -70,
                  scale: mobileCount === 1 ? 1 : 0,
                  zIndex: 1,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: 1,
                  delay: 0,
                }}
              ></motion.img>
              <motion.img
                src={InLife}
                alt='inlife'
                initial={{
                  opacity: mobileCount === 2 ? 0 : 0,
                  translateY: mobileCount === 2 ? "-5%" : "0",
                  rotateY: -70,
                  scale: 0.8,
                  zIndex: 1,
                }}
                animate={{
                  opacity: mobileCount === 2 ? (isTabletOrMobile ? 1 : 1) : 0,
                  translateY: mobileCount === 2 ? "-5%" : "0",
                  rotateY: mobileCount === 2 ? 0 : -70,
                  scale: mobileCount === 2 ? 1 : 0,
                  zIndex: 1,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: 1,
                  delay: 0,
                }}
              ></motion.img>
              <motion.img
                src={FortuneLife}
                alt='fortune-life'
                initial={{
                  opacity: mobileCount === 3 ? 0 : 0,
                  translateY: mobileCount === 3 ? "-5%" : "0",
                  rotateY: -70,
                  scale: 0.8,
                  zIndex: 1,
                }}
                animate={{
                  opacity: mobileCount === 3 ? (isTabletOrMobile ? 1 : 1) : 0,
                  translateY: mobileCount === 3 ? "-5%" : "0",
                  rotateY: mobileCount === 3 ? 0 : -70,
                  scale: mobileCount === 3 ? 1 : 0,
                  zIndex: 1,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: 1,
                  delay: 0,
                }}
              ></motion.img>
              <motion.img
                src={Generali}
                alt='generali'
                initial={{
                  opacity: mobileCount === 4 ? 0 : 0,
                  translateY: mobileCount === 4 ? "-5%" : "0",
                  rotateY: -70,
                  scale: 0.5,
                  zIndex: 1,
                }}
                animate={{
                  opacity: mobileCount === 4 ? (isTabletOrMobile ? 1 : 1) : 0,
                  translateY: mobileCount === 4 ? "-5%" : "0",
                  rotateY: mobileCount === 4 ? 0 : -70,
                  scale: mobileCount === 4 ? 1 : 0,
                  zIndex: 1,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: 1,
                  delay: 0,
                }}
              ></motion.img>
              <motion.img
                src={Wellness_Kit}
                alt='wellness-kit'
                initial={{
                  opacity: mobileCount === 5 ? 0 : 0,
                  translateY: mobileCount === 5 ? "-5%" : "0",
                  rotateY: -70,
                  scale: 0.5,
                  zIndex: 1,
                }}
                animate={{
                  opacity: mobileCount === 5 ? (isTabletOrMobile ? 1 : 1) : 0,
                  translateY: mobileCount === 5 ? "-5%" : "0",
                  rotateY: mobileCount === 5 ? 0 : -70,
                  scale: mobileCount === 5 ? 1 : 0,
                  zIndex: 1,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: 1,
                  delay: 0,
                }}
              ></motion.img>
              <motion.img
                src={My_Wheelbox}
                alt='my-wheelbox'
                initial={{
                  opacity: mobileCount === 6 ? 0 : 0,
                  translateY: mobileCount === 6 ? "-5%" : "0",
                  rotateY: -70,
                  scale: 0.5,
                  zIndex: 1,
                }}
                animate={{
                  opacity: mobileCount === 6 ? (isTabletOrMobile ? 1 : 1) : 0,
                  translateY: mobileCount === 6 ? "-5%" : "0",
                  rotateY: mobileCount === 6 ? 0 : -70,
                  scale: mobileCount === 6 ? 1 : 0,
                  zIndex: 1,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: 1,
                  delay: 0,
                }}
              ></motion.img>
            </motion.div>

            <div className='_serv__btn--1'></div>
            <div className='_serv__btn--2'></div>
          </div>
          <BackgroundDiv />
        </motion.div>
      </>
    );
  };

  const InnerText = () => {
    <>
      <div className='_serv__text--inner'>
        <div className='_serv__text--logo'>
          <LogoDiv />
        </div>
        <ActiveContainer />
      </div>
    </>;
  };

  const ActiveContainer = () => {
    switch (parentCount) {
      case 1:
        return (
          <>
            <div className='services__selection--item'>
              {backroomData[count - 1].title}
            </div>
            <div className='services__selection--desc'>
              {backroomData[count - 1].desc}
            </div>
          </>
        );

      case 2:
        return (
          <>
            {isDesktopOrLaptop && (
              <>
                <div className='_serv__text--title'>
                  {mobileData[mobileCount - 1].title}
                </div>
                <div className='_serv__text--desc'>
                  {mobileData[mobileCount - 1].desc}
                </div>
                {mobileCount === 5 && (
                  <div className='tos-links' style={{ margin: `2% 0%` }}>
                    Please see our{" "}
                    <Link
                      to='/health-log-kit/privacy-notice'
                      id='tos-link'
                      className='hlk'
                    >
                      Privacy Notice
                    </Link>{" "}
                    and{" "}
                    <Link
                      to='/health-log-kit/terms-and-conditions'
                      id='tos-link'
                      className='hlk'
                    >
                      Terms and Conditions
                    </Link>{" "}
                    for more details or if you're an existing user,{" "}
                    <Link
                      to='/health-log-kit/user-data-deletion'
                      id='tos-link'
                      className='hlk'
                    >
                      click here
                    </Link>{" "}
                    to delete your account.
                  </div>
                )}
                {mobileCount === 6 && (
                  <div className='tos-links' style={{ margin: `2% 0%` }}>
                    Please see our{" "}
                    <Link
                      to='/my-wheelbox/privacy-notice'
                      id='tos-link'
                      className='wb'
                    >
                      Privacy Notice
                    </Link>{" "}
                    and{" "}
                    <Link
                      to='/my-wheelbox/terms-and-conditions'
                      id='tos-link'
                      className='wb'
                    >
                      Terms and Conditions
                    </Link>{" "}
                    for more details or if you're an existing user,{" "}
                    <Link
                      to='/my-wheelbox/user-data-deletion'
                      id='tos-link'
                      className='wb'
                    >
                      click here
                    </Link>{" "}
                    to delete your account.
                  </div>
                )}
              </>
            )}
            {isTabletOrMobile && (
              <>
                <div className='services__selection--item'>
                  {mobileData[mobileCount - 1].title}
                </div>
                <div className='services__selection--desc'>
                  {mobileData[mobileCount - 1].desc}
                </div>
                {mobileCount === 5 && (
                  <div
                    className='tos-links'
                    style={{ margin: `2% 0%`, color: "var(--grey)" }}
                  >
                    Please see our{" "}
                    <Link
                      to='/health-log-kit/privacy-notice'
                      style={{ color: "var(--primary)" }}
                    >
                      Privacy Notice
                    </Link>{" "}
                    and{" "}
                    <Link
                      to='/health-log-kit/terms-and-conditions'
                      style={{ color: "var(--primary)" }}
                    >
                      Terms and Conditions
                    </Link>{" "}
                    for more details.
                  </div>
                )}
                {mobileCount === 6 && (
                  <div
                    className='tos-links'
                    style={{ margin: `2% 0%`, color: "var(--grey)" }}
                  >
                    Please see our{" "}
                    <Link
                      to='/my-wheelbox/privacy-notice'
                      style={{ color: "var(--primary)" }}
                    >
                      Privacy Notice
                    </Link>{" "}
                    and{" "}
                    <Link
                      to='/my-wheelbox/terms-and-conditions'
                      style={{ color: "var(--primary)" }}
                    >
                      Terms and Conditions
                    </Link>{" "}
                    for more details.
                  </div>
                )}
              </>
            )}
          </>
        );
      case 3:
        return (
          <>
            <div className='services__selection--item web'>
              {webData[count - 1].title}
            </div>
            <div className='services__selection--desc web'>
              {webData[count - 1].desc}
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const handleCount = (e) => {
    if (isTabletOrMobile) {
      setCount(count === 3 ? 1 : count + 1);
    } else {
      if (e.target.parentElement.classList.contains("item-1")) {
        setCount(1);
      } else if (e.target.parentElement.classList.contains("item-2")) {
        setCount(2);
      } else if (e.target.parentElement.classList.contains("item-3")) {
        setCount(3);
      }
    }
  };

  const handleMobileCount__hero = () => {
    if (isTabletOrMobile) {
      // setMobileCount(mobileCount === 3 ? 1 : mobileCount + 1);

      setMobileCount(mobileCount === 6 ? 1 : mobileCount + 1);

      switch (mobileCount) {
        case 6:
          setBackground(BgWheelbox);
          setMobileLogo(LogoWheelbox);
          break;
        case 5:
          setBackground(BgWellnessKit);
          setMobileLogo(LogoWellnessKit);
          break;
        case 4:
          setBackground(BgMyDrive);
          setMobileLogo(LogoMyDrive);
          break;
        case 1:
          setBackground(BgInLife);
          setMobileLogo(LogoInLife);
          break;
        case 2:
          setBackground(BgFortuneLife);
          setMobileLogo(LogoFortuneLife);
          break;
        default:
          setBackground(BgGenerali);
          setMobileLogo(LogoGenerali);
          break;
      }

      // switch (mobileCount) {
      //   case 3:
      //     setBackground(BgMyDrive);
      //     setMobileLogo(LogoMyDrive);
      //     break;
      //   case 1:
      //     setBackground(BgInLife);
      //     setMobileLogo(LogoInLife);
      //     break;
      //   default:
      //     setBackground(BgFortuneLife);
      //     setMobileLogo(LogoFortuneLife);
      //     break;
      // }
    }
  };

  const handleMobileCount = (e) => {
    setIsActive(!isActive);

    if (
      e.target.parentElement.classList.contains("item-1") ||
      e.target.classList.contains("item-1")
    ) {
      setMobileCount(1);
      setBackground(BgMyDrive);
      setMobileLogo(LogoMyDrive);
    } else if (
      e.target.parentElement.classList.contains("item-2") ||
      e.target.classList.contains("item-2")
    ) {
      setMobileCount(2);
      setBackground(BgInLife);
      setMobileLogo(LogoInLife);
    } else if (
      e.target.parentElement.classList.contains("item-3") ||
      e.target.classList.contains("item-3")
    ) {
      setMobileCount(3);
      setBackground(BgFortuneLife);
      setMobileLogo(LogoFortuneLife);
    } else {
      setMobileCount(4);
      setBackground(BgGenerali);
      setMobileLogo(LogoGenerali);
    }

    setTimeout(() => {
      setIsActive(isActive);
    }, 100);
  };

  const handleWebCount__hero = () => {
    if (isTabletOrMobile) {
      setCount(count === 3 ? 1 : count + 1);

      switch (count) {
        case 3:
          setWebBackground(BgMember);
          setWebIllus(IllusMember);
          break;
        case 1:
          setWebBackground(BgSales);
          setWebIllus(IllusSales);
          break;
        default:
          setWebBackground(BgCorporate);
          setWebIllus(IllusCorporate);
          break;
      }
    }
  };

  // const handleWebCount = (e) => {
  //   setIsWebActive(!isWebActive);
  //   if (e.target.parentElement.classList.contains("item-1")) {
  //     setCount(1);
  //     setWebBackground(BgMember);
  //     setWebIllus(IllusMember);
  //   } else if (e.target.parentElement.classList.contains("item-2")) {
  //     setCount(2);
  //     setWebBackground(BgSales);
  //     setWebIllus(IllusSales);
  //   } else if (e.target.parentElement.classList.contains("item-3")) {
  //     setCount(3);
  //     setWebBackground(BgCorporate);
  //     setWebIllus(IllusCorporate);
  //   }

  //   setTimeout(() => {
  //     setIsWebActive(isWebActive);
  //   }, 100);
  // };

  const handleClick = (e) => {
    setClick(!click);

    if (e.target.classList.contains("services__backroom-selector")) {
      setParentCount(1);
    } else if (e.target.classList.contains("services__mobile-selector")) {
      setParentCount(2);
    } else if (e.target.classList.contains("services__web-selector")) {
      setParentCount(3);
    } else {
      setParentCount(0);
    }
  };

  const handleHover = (e) => {
    if (isDesktopOrLaptop) {
      let target = null;

      if (e.target.classList.contains("services__backroom-selector")) {
        target = document.getElementsByClassName(
          "services__backroom-selector serv-bg"
        );
        setCount(1);
      } else if (e.target.classList.contains("services__mobile-selector")) {
        target = document.getElementsByClassName(
          "services__mobile-selector serv-bg"
        );
        setMobileCount(1);
        setBackground(BgMyDrive);
        setMobileLogo(LogoMyDrive);
      } else {
        target = document.getElementsByClassName(
          "services__web-selector serv-bg"
        );
        setCount(1);
        setWebBackground(BgMember);
        setWebIllus(IllusMember);
      }

      if (hover) {
        target[0].classList.add("active");
      } else {
        target[0].classList.remove("active");
      }

      setHover(!hover);
    }
  };

  const variantsBg = {
    closed: {
      opacity: 1,
      scale: 1,
    },
  };

  const BackgroundDiv = () => {
    return (
      <motion.div
        className='services__container-mobile-bg'
        initial={{
          backgroundImage: `url(${
            mobileCount === 1
              ? BgMyDrive
              : mobileCount === 2
              ? BgInLife
              : mobileCount === 3
              ? BgFortuneLife
              : mobileCount === 4
              ? BgGenerali
              : mobileCount === 5
              ? BgWellnessKit
              : BgWheelbox
          })`,
          opacity: 0,
          scale: 0.8,
          position: "absolute",
        }}
        animate={isActive ? "open" : "closed"}
        variants={variantsBg}
        transition={{
          ease: [0.6, 0.01, -0.05, 0.95],
          duration: 0.8,
          delay: 0,
        }}
      ></motion.div>
    );
  };

  const variantsLogo = {
    open: {
      opacity: 0,
      scale: 0.5,
      transformX: -20,
      rotateY: 40,
      transformOrigin: "left",
    },
    closed: {
      opacity: 1,
      scale: 0.5,
      transformX: 0,
      rotateY: 0,
      transformOrigin: "left",
    },
  };

  const LogoDiv = () => {
    return (
      <motion.div
        initial={{
          backgroundImage: `url(${
            mobileCount === 1
              ? LogoMyDrive
              : mobileCount === 2
              ? LogoInLife
              : mobileCount === 3
              ? LogoFortuneLife
              : mobileCount === 4
              ? LogoGenerali
              : mobileCount === 5
              ? LogoWellnessKit
              : LogoWheelbox
          })`,
          opacity: 0,
          scale: 0.8,
          transformX: -20,
          rotateY: 40,
          transformOrigin: "left",
        }}
        className='services__container-mobile-logo'
        animate={isActive ? "open" : "closed"}
        variants={variantsLogo}
        transition={{
          ease: [0.6, 0.01, -0.05, 0.95],
          duration: 1.2,
          delay: 0,
        }}
      ></motion.div>
    );
  };

  const BackroomIndicator = () => {
    return (
      <>
        <p
          style={{
            display: isTabletOrMobile ? "block" : "none",
          }}
        >
          01
        </p>
      </>
    );
  };

  const MobileIndicator = () => {
    return (
      <>
        <p
          style={{
            display: isTabletOrMobile ? "block" : "none",
          }}
        >
          02
        </p>
      </>
    );
  };

  const WebIndicator = () => {
    return (
      <>
        <p
          style={{
            display: isTabletOrMobile ? "block" : "none",
          }}
        >
          03
        </p>
      </>
    );
  };

  const IndicatorVariants = {
    expanded: {
      width: "50px",
      opacity: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1.2,
        delay: 0,
      },
    },
    collapsed: {
      width: "5px",
      opacity: 0.5,
      when: "afterChildren",
    },
  };
  return (
    <div
      className='services'
      style={{
        height: height - (isDesktopOrLaptop ? 0 : 100),
        width: width,
        backgroundColor: isDesktopOrLaptop ? "#fff" : "var(--white)",
      }}
    >
      <div
        className='services__container'
        style={{
          height: height * 0.95 - (isDesktopOrLaptop ? 50 : 100),
          width: width * 0.95 - (isDesktopOrLaptop ? 50 : 100),
          backgroundColor: "#fff",
          margin: "auto",
          position: "relative",
        }}
      >
        <div className='services__navigation'>
          <div className='services__title'>Services</div>
          {isDesktopOrLaptop && (
            <motion.div
              className='services__indicator'
              initial={{
                opacity: 0,
                translateX: "-20%",
              }}
              animate={{
                opacity: parentCount > 0 ? 1 : 0,
                translateX: parentCount > 0 ? "0%" : "-20%",
              }}
              transition={{
                ease: [0.6, 0.01, -0.05, 0.95],
                duration: 0.8,
                delay: 0,
              }}
            >
              {parentCount === 1
                ? count
                : parentCount === 2
                ? mobileCount
                : parentCount === 3
                ? count
                : ""}
            </motion.div>
          )}
          <div className='services__desc'>
            Software development services for your digital roadmap
          </div>
        </div>
        <div
          className='services__selectors'
          style={{ zIndex: parentCount === 0 ? 5 : 0 }}
        >
          <motion.div
            className='services__backroom-selector serv-item'
            onMouseOver={handleHover}
            onMouseLeave={handleHover}
            onClick={() => {
              setParentCount(1);
              setClick(!click);
              setCount(1);
            }}
            initial={{ rotateY: 0 }}
            animate={{
              rotateY: click ? 90 : 0,
              transformOrigin: "left",
            }}
            transition={{
              ease: [0.6, 0.01, -0.05, 0.95],
              duration: 0.8,
              delay: 0,
            }}
          >
            <BackroomIndicator />
            Backroom
          </motion.div>
          <motion.div
            className='services__mobile-selector serv-item'
            onMouseOver={handleHover}
            onMouseLeave={handleHover}
            onClick={() => {
              setParentCount(2);
              setClick(!click);
              setMobileCount(1);
            }}
            initial={{ rotateY: 0 }}
            animate={{
              rotateY: click ? 90 : 0,
              transformOrigin: "left",
            }}
            transition={{
              ease: [0.6, 0.01, -0.05, 0.95],
              duration: 0.8,
              delay: 0.3,
            }}
          >
            <MobileIndicator />
            Mobile
          </motion.div>
          <motion.div
            className='services__web-selector serv-item'
            onMouseOver={handleHover}
            onMouseLeave={handleHover}
            onClick={() => {
              setParentCount(3);
              setClick(!click);
              setCount(1);
            }}
            initial={{ rotateY: 0 }}
            animate={{
              rotateY: click ? 90 : 0,
              transformOrigin: "left",
            }}
            transition={{
              ease: [0.6, 0.01, -0.05, 0.95],
              duration: 0.5,
              delay: 0.8,
            }}
          >
            <WebIndicator />
            Web
          </motion.div>
        </div>
        <motion.div
          className={`services__showcase ${click ? "active" : ""}`}
          initial={{
            height: 0,
            opacity: 0,
            zIndex: 0,
          }}
          animate={{
            height: click ? (height * isDesktopOrLaptop ? 0.95 : "100%") : 0,
            opacity: click ? 1 : 0,
          }}
          transition={{
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 0.8,
            delay: click ? 1.5 : 0,
          }}
        >
          {isTabletOrMobile && (
            <>
              <div className='services__slide--containers'>
                <motion.div
                  className='services__slide prev'
                  onClick={() => {
                    if (parentCount !== 2) {
                      if (count !== 1) {
                        setCount(count - 1);
                      } else {
                        setCount(3);
                      }
                    } else {
                      if (mobileCount !== 1) {
                        setMobileCount(mobileCount - 1);
                      } else {
                        setMobileCount(4);
                        // setMobileCount(3);
                      }
                    }
                  }}
                >
                  <FaArrowLeft />
                </motion.div>
                <motion.div
                  className='services__slide next'
                  onClick={() => {
                    if (parentCount !== 2) {
                      if (count !== 3) {
                        setCount(count + 1);
                      } else {
                        setCount(1);
                      }
                    } else {
                      if (mobileCount !== 4) {
                        // if (mobileCount !== 3) {
                        setMobileCount(mobileCount + 1);
                      } else {
                        setMobileCount(1);
                      }
                    }
                  }}
                >
                  <FaArrowRight />
                </motion.div>
              </div>
            </>
          )}
          {isDesktopOrLaptop && (
            <>
              <motion.div
                className='services__slide prev'
                onClick={() => {
                  if (parentCount !== 2) {
                    if (count !== 1) {
                      setCount(count - 1);
                    } else {
                      setCount(3);
                    }
                  } else {
                    if (mobileCount !== 1) {
                      setMobileCount(mobileCount - 1);
                    } else {
                      setMobileCount(6);
                      // setMobileCount(3);
                    }
                  }
                }}
              >
                <FaArrowLeft />
              </motion.div>
              <motion.div
                className='services__slide next'
                onClick={() => {
                  if (parentCount !== 2) {
                    if (count !== 3) {
                      setCount(count + 1);
                    } else {
                      setCount(1);
                    }
                  } else {
                    if (mobileCount !== 6) {
                      // if (mobileCount !== 3) {
                      setMobileCount(mobileCount + 1);
                    } else {
                      setMobileCount(1);
                    }
                  }
                }}
              >
                <FaArrowRight />
              </motion.div>
            </>
          )}

          <ServiceIndicators />
          <motion.div id='btnBack' onClick={click ? handleClick : null}>
            <FaTimes />
          </motion.div>
          <motion.div
            className={`services__backroom-container ${
              parentCount === 1 ? "active" : ""
            }`}
            initial={{
              opacity: 0,
              zIndex: parentCount === 2 ? 0 : -2,
            }}
            animate={{
              opacity: parentCount === 1 ? 1 : 0,
              zIndex: parentCount === 2 ? 1 : -2,
            }}
          >
            <motion.div
              className='services__container-hero'
              style={{
                background: `var(--primary-gradient)`,
              }}
              onClick={handleCount}
            >
              <motion.img
                src={DataWarehousing}
                alt='data-warehousing'
                initial={{
                  opacity: count === 1 ? 0 : 0,
                  translateX: count === 1 ? 500 : 0,
                  scale: 0.8,
                }}
                animate={{
                  opacity: count === 1 ? 1 : 0,
                  translateX: count === 1 ? 0 : 0,
                  scale: count === 1 ? 0.8 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: 1,
                  delay: 0,
                }}
              ></motion.img>
              <motion.img
                src={FinancialDataServices}
                alt='financial-data-services'
                initial={{
                  opacity: count === 2 ? 0 : 0,
                  translateX: count === 2 ? 500 : 0,
                  scale: 0.8,
                }}
                animate={{
                  opacity: count === 2 ? 1 : 0,
                  translateX: count === 2 ? 0 : 0,
                  scale: count === 2 ? 0.8 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: 1,
                  delay: 0,
                }}
              ></motion.img>
              <motion.img
                src={DataAnalytics}
                alt='data-analytics'
                initial={{
                  opacity: count === 3 ? 0 : 0,
                  translateX: count === 3 ? 500 : 0,
                  scale: 0.8,
                }}
                animate={{
                  opacity: count === 3 ? 1 : 0,
                  translateX: count === 3 ? 0 : 0,
                  scale: count === 3 ? 0.8 : 0,
                }}
                transition={{
                  ease: [0.6, 0.01, -0.05, 0.95],
                  duration: 1,
                  delay: 0,
                }}
              ></motion.img>
            </motion.div>
            {isTabletOrMobile && (
              <div className='mobile__slide-indicator'>
                <div
                  className={`ind indicator-1 ${count === 1 ? "active" : ""}`}
                ></div>
                <div
                  className={`ind indicator-2 ${count === 2 ? "active" : ""}`}
                ></div>
                <div
                  className={`ind indicator-3 ${count === 3 ? "active" : ""}`}
                ></div>
              </div>
            )}
            <div className='services__container-selection'>
              <ActiveContainer />
              {/* <div
                className={`services__selection item-1 ${
                  count === 1 ? "active" : ""
                }`}
                onClick={handleCount}
              >
                <div className='services__selection-title'>
                  Data Warehousing
                </div>
                <div className='services__selection-desc'>
                  An Enterprise-grade Data Warehouse, a system designed
                  intristically to the requirement of BDO Life Assurance
                  Company, Inc.
                </div>
              </div>
              <div
                className={`services__selection item-2 ${
                  count === 2 ? "active" : ""
                }`}
                onClick={handleCount}
              >
                <div className='services__selection-title'>
                  Financial Data Services
                </div>
                <div className='services__selection-desc'>
                  Keeping track of financial information and reporting needs, a
                  project designed for a Japanese engineering company here in
                  the Philippines.
                </div>
              </div>
              <div
                className={`services__selection item-3 ${
                  count === 3 ? "active" : ""
                }`}
                onClick={handleCount}
              >
                <div className='services__selection-title'>
                  Deep Data Analytics
                </div>
                <div className='services__selection-desc'>
                  A backroom tools designed for the Actuarial Society of the
                  Philippines or ASP that enables them to produce the
                  consolidated data needed by the Insurance Commission.
                </div>
              </div> */}
            </div>
          </motion.div>

          {isDesktopOrLaptop && <ServicesDesktopNew mobileData={mobileData} />}

          {isTabletOrMobile && (
            <motion.div
              className={`services__mobile-container ${
                parentCount === 2 ? "active" : ""
              }`}
              initial={{
                opacity: 0,
                zIndex: parentCount === 2 ? 0 : -2,
              }}
              animate={{
                opacity: parentCount === 2 ? 2 : 0,
                zIndex: parentCount === 2 ? 1 : -2,
              }}
            >
              <motion.div
                className='services__container-hero'
                style={{
                  background: "white",
                  overflow: "hidden",
                }}
                onClick={handleMobileCount__hero}
              >
                <motion.img
                  src={MyDrive_mobile}
                  alt='my-drive'
                  initial={{
                    opacity: mobileCount === 1 ? 0 : 0,
                    translateX: mobileCount === 1 ? 500 : 0,
                    translateY: "50%",
                    rotateY: -70,
                    scale: 0.8,
                    zIndex: 1,
                  }}
                  animate={{
                    opacity: mobileCount === 1 ? (isTabletOrMobile ? 1 : 1) : 0,
                    translateX: mobileCount === 1 ? 0 : 0,
                    translateY: mobileCount === 1 ? "60%" : "50%",
                    rotateY: mobileCount === 1 ? 0 : -70,
                    scale: mobileCount === 1 ? 1 : 0,
                    zIndex: 1,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 1,
                    delay: 0,
                  }}
                ></motion.img>
                <motion.img
                  src={InLife_mobile}
                  alt='inlife'
                  initial={{
                    opacity: mobileCount === 2 ? 0 : 0,
                    translateX: mobileCount === 2 ? 500 : 0,
                    translateY: "50%",
                    rotateY: -70,
                    scale: 0.8,
                    zIndex: 1,
                  }}
                  animate={{
                    opacity: mobileCount === 2 ? (isTabletOrMobile ? 1 : 1) : 0,
                    translateX: mobileCount === 2 ? 0 : 0,
                    translateY: mobileCount === 2 ? "60%" : "50%",
                    rotateY: mobileCount === 2 ? 0 : -70,
                    scale: mobileCount === 2 ? 1 : 0,
                    zIndex: 1,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 1,
                    delay: 0,
                  }}
                ></motion.img>
                <motion.img
                  src={FortuneLife_mobile}
                  alt='fortune-life'
                  initial={{
                    opacity: mobileCount === 3 ? 0 : 0,
                    translateX: mobileCount === 3 ? 500 : 0,
                    translateY: "50%",
                    rotateY: -70,
                    scale: 0.8,
                    zIndex: 1,
                  }}
                  animate={{
                    opacity: mobileCount === 3 ? (isTabletOrMobile ? 1 : 1) : 0,
                    translateX: mobileCount === 3 ? 0 : 0,
                    translateY: mobileCount === 3 ? "60%" : "50%",
                    rotateY: mobileCount === 3 ? 0 : -70,
                    scale: mobileCount === 3 ? 1 : 0,
                    zIndex: 1,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 1,
                    delay: 0,
                  }}
                ></motion.img>
                <motion.img
                  src={Generali_mobile}
                  alt='generali'
                  initial={{
                    opacity: mobileCount === 4 ? 0 : 0,
                    translateX: mobileCount === 4 ? 500 : 0,
                    translateY: mobileCount === 4 ? "50%" : "50%",
                    rotateY: -70,
                    scale: 0.5,
                    zIndex: 1,
                  }}
                  animate={{
                    opacity: mobileCount === 4 ? (isTabletOrMobile ? 1 : 1) : 0,
                    translateX: mobileCount === 4 ? 0 : 0,
                    translateY: mobileCount === 4 ? "60%" : "50%",
                    rotateY: mobileCount === 4 ? 0 : -70,
                    scale: mobileCount === 4 ? 1 : 0,
                    zIndex: 1,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 1,
                    delay: 0,
                  }}
                ></motion.img>
                <motion.img
                  src={Wellness_Kit_mobile}
                  alt='generali'
                  initial={{
                    opacity: mobileCount === 5 ? 0 : 0,
                    translateX: mobileCount === 5 ? 500 : 0,
                    translateY: mobileCount === 5 ? "50%" : "50%",
                    rotateY: -70,
                    scale: 0.5,
                    zIndex: 1,
                  }}
                  animate={{
                    opacity: mobileCount === 5 ? (isTabletOrMobile ? 1 : 1) : 0,
                    translateX: mobileCount === 5 ? 0 : 0,
                    translateY: mobileCount === 5 ? "60%" : "50%",
                    rotateY: mobileCount === 5 ? 0 : -70,
                    scale: mobileCount === 5 ? 1 : 0,
                    zIndex: 1,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 1,
                    delay: 0,
                  }}
                ></motion.img>
                <motion.img
                  src={My_Wheelbox_mobile}
                  alt='generali'
                  initial={{
                    opacity: mobileCount === 6 ? 0 : 0,
                    translateX: mobileCount === 6 ? 500 : 0,
                    translateY: mobileCount === 6 ? "50%" : "50%",
                    rotateY: -70,
                    scale: 0.5,
                    zIndex: 1,
                  }}
                  animate={{
                    opacity: mobileCount === 6 ? (isTabletOrMobile ? 1 : 1) : 0,
                    translateX: mobileCount === 6 ? 0 : 0,
                    translateY: mobileCount === 6 ? "60%" : "50%",
                    rotateY: mobileCount === 6 ? 0 : -70,
                    scale: mobileCount === 6 ? 1 : 0,
                    zIndex: 1,
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 1,
                    delay: 0,
                  }}
                ></motion.img>
                <BackgroundDiv />
                <LogoDiv />
              </motion.div>

              {isTabletOrMobile && (
                <div className='mobile__slide-indicator'>
                  <div
                    className={`ind indicator-1 ${
                      mobileCount === 1 ? "active" : ""
                    }`}
                  ></div>
                  <div
                    className={`ind indicator-2 ${
                      mobileCount === 2 ? "active" : ""
                    }`}
                  ></div>
                  <div
                    className={`ind indicator-3 ${
                      mobileCount === 3 ? "active" : ""
                    }`}
                  ></div>
                  <div
                    className={`ind indicator-4 ${
                      mobileCount === 4 ? "active" : ""
                    }`}
                  ></div>
                  <div
                    className={`ind indicator-5 ${
                      mobileCount === 5 ? "active" : ""
                    }`}
                  ></div>
                  <div
                    className={`ind indicator-6 ${
                      mobileCount === 6 ? "active" : ""
                    }`}
                  ></div>
                </div>
              )}

              <div className='services__container-selection'>
                <ActiveContainer />
                {/* <div
                className={`services__selection item-1 ${
                  mobileCount === 1 ? "active" : ""
                }`}
                onClick={handleMobileCount}
              >
                <div className='services__selection-title'>
                  My Drive | Motor Insurance
                </div>
                <div className='services__selection-desc'>
                  My Drive offers a convenient way for Paramount Insured Members
                  to request a quotation, be notified during policy renewal and
                  for them to have a maintenance of their vehicle records.
                </div>
              </div>
              <div
                className={`services__selection item-2 ${
                  mobileCount === 2 ? "active" : ""
                }`}
                onClick={handleMobileCount}
              >
                <div className='services__selection-title'>
                  HealthCare Member Mobile app for HMO and Insurance
                </div>
                <div className='services__selection-desc'>
                  HealthCare Member app is a pixel perfect design with the
                  simplicity and sophisticated functionalities for the Benefit
                  of the Members.
                </div>
              </div>
              <div
                className={`services__selection item-3 ${
                  mobileCount === 3 ? "active" : ""
                }`}
                onClick={handleMobileCount}
              >
                <div className='services__selection-title'>
                  Retail Life Insurance Selling for FortuneLife
                </div>
                <div className='services__selection-desc'>
                  A mobile app design for helping FortuneLife policy holders to
                  have a real-time and seamless insurance application from
                  registration all the way to policy issuance.
                </div>
              </div>
              <div
                className={`services__selection item-4 ${
                  mobileCount === 4 ? "active" : ""
                }`}
                onClick={handleMobileCount}
              >
                <div className='services__selection-title'>Generali</div>
                <div className='services__selection-desc'>
                  Lorem Ipsum dolor sit amet
                </div>
              </div> */}
              </div>
            </motion.div>
          )}

          <motion.div
            className={`services__web-container ${
              parentCount === 3 ? "active" : ""
            }`}
            initial={{
              opacity: 0,
              zIndex: parentCount === 3 ? 0 : -2,
            }}
            animate={{
              opacity: parentCount === 3 ? 1 : 0,
            }}
          >
            <motion.div
              className='services__container-hero'
              style={{
                backgroundImage: `url(${webBackground})`,
                background: `var(--primary-gradient)`,
              }}
              onClick={handleWebCount__hero}
            >
              <InnerSlide />
              {/* <WebIllusDiv /> */}
              {/* <LogoDiv /> */}
            </motion.div>
            {isTabletOrMobile && (
              <div className='mobile__slide-indicator'>
                <div
                  className={`ind indicator-1 ${count === 1 ? "active" : ""}`}
                ></div>
                <div
                  className={`ind indicator-2 ${count === 2 ? "active" : ""}`}
                ></div>
                <div
                  className={`ind indicator-3 ${count === 3 ? "active" : ""}`}
                ></div>
              </div>
            )}

            {isDesktopOrLaptop && (
              <div className='services__selectors-after'>
                <motion.div
                  className={`services__backroom-selector serv-bg ${
                    !setHover ? "active" : ""
                  }`}
                  initial={{ rotateY: 0 }}
                  animate={{
                    rotateY: click ? 90 : 0,
                    transformOrigin: "left",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                    delay: 0,
                  }}
                ></motion.div>
                <motion.div
                  className={`services__mobile-selector serv-bg ${
                    !setHover ? "active" : ""
                  }`}
                  initial={{ rotateY: 0 }}
                  animate={{
                    rotateY: click ? 90 : 0,
                    transformOrigin: "left",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                    delay: 0.3,
                  }}
                ></motion.div>
                <motion.div
                  className={`services__web-selector serv-bg ${
                    !setHover ? "active" : ""
                  }`}
                  initial={{ rotateY: 0 }}
                  animate={{
                    rotateY: click ? 90 : 0,
                    transformOrigin: "left",
                  }}
                  transition={{
                    ease: [0.6, 0.01, -0.05, 0.95],
                    duration: 0.8,
                    delay: 0.8,
                  }}
                ></motion.div>
              </div>
            )}

            <div className='services__container-selection'>
              <ActiveContainer />
              {/* <div
                className={`services__selection item-1 ${
                  count === 1 ? "active" : ""
                }`}
                onClick={handleWebCount}
              >
                <div className='services__selection-title'>Member Portal</div>
                <div className='services__selection-desc'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  facilisis vestibulum volutpat. Mauris placerat viverra nibh ac
                  faucibus. Cras tincidunt molestie commodo. Donec scelerisque
                  sapien in leo congue, quis placerat leo scelerisque.
                </div>
              </div>
              <div
                className={`services__selection item-2 ${
                  count === 2 ? "active" : ""
                }`}
                onClick={handleWebCount}
              >
                <div className='services__selection-title'>Sales Portal</div>
                <div className='services__selection-desc'>
                  Sed semper maximus libero non elementum. Proin luctus maximus
                  fermentum. Mauris at eros nec erat laoreet congue eu ut
                  ligula. Mauris diam nisi, dapibus sit amet sagittis eget,
                  molestie nec felis. Etiam iaculis pharetra tristique.
                </div>
              </div>
              <div
                className={`services__selection item-3 ${
                  count === 3 ? "active" : ""
                }`}
                onClick={handleWebCount}
              >
                <div className='services__selection-title'>Corporate HR</div>
                <div className='services__selection-desc'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  facilisis vestibulum volutpat. Mauris placerat viverra nibh ac
                  faucibus. Cras tincidunt molestie commodo. Donec scelerisque
                  sapien in leo congue, quis placerat leo scelerisque.
                </div>
              </div> */}
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
}

export default Services;
