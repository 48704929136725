import React from "react";
import "./ErrorPage.scss";

const ErrorPage = () => {
  return (
    <div className='error-page'>
      <h1>404</h1>
      <div>
        The page you're looking for doesn't seem to exist. Please make another
        request.
      </div>
    </div>
  );
};

export default ErrorPage;
